import React, { useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import moment from 'moment-timezone'

const LogsCard = ({ id, setShowLogs, logs }) => {
  const timezone = 'Asia/Calcutta'
  useEffect(() => {
    if (!id) {
      alert('No lab id found please contact labs team')
      setShowLogs('false')
    }
  }, [id, setShowLogs])

  return (
    <div className="labs-add-student-card-main">
      <div
        className="labs-add-student-card-content"
        style={{
          width: '60%',
          maxHeight: '80vh',
          overflowY: 'scroll',
          scrollbarWidth: 'thin',
        }}
      >
        <div className="labs-add-student-card-header">
          <span> Machine Logs</span>

          <Image
            src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
            onClick={() => setShowLogs(false)}
            style={{ cursor: 'pointer' }}
          />
        </div>

        <hr />

        {logs &&
          logs.map((elem, i) => {
            return (
              <Row key={i}>
                <Col md={3}>
                  {moment
                    .utc(elem.time)
                    .tz(timezone)
                    .format('YYYY-MM-DD HH:mm:ss') + ' (IST)'}
                </Col>

                <Col md={9}>
                  {i + 1}) {elem.msg} <hr />
                </Col>
              </Row>
            )
          })}
      </div>
    </div>
  )
}

export default LogsCard
