import React , {useEffect} from 'react'
import { history } from '../../..'

const Sidebar2 = ({ active,jobid }) => {
  // const { jobId } = useParams();

  // useEffect(() => {
  //   if (!jobId) {
  //     history.push('/');
  //   }
  // }, []);
  return (
    <div className="jobs-sidebar">
        <div
        className={
          active === 0
            ? 'jobs-sidebar-elem-0 jobs-sidebar-active'
            : `jobs-sidebar-elem-0`
        }
        onClick={() => {
          history.push('/career-services/')
        }}
      >
       <img
          src="https://webcdn.imarticus.org/Placement/chevron-up2.svg"
          height={20}
          width={20}
          alt="location"
          style={{paddingBottom:"1px"}}
        />&nbsp;&nbsp;&nbsp;Back
      </div>
      <div
        className={
          active === 1
            ? 'jobs-sidebar-elem jobs-sidebar-active'
            : `jobs-sidebar-elem`
        }
        onClick={() => {
          history.push(`/career-module/create-job-description/${jobid}`)
        }}
      >
        Job Description
      </div>

      <div
        className={
          active === 2
            ? 'jobs-sidebar-elem jobs-sidebar-active'
            : `jobs-sidebar-elem`
        }
        onClick={() => {
          history.push(`/career-module/add-job-criteria/${jobid}`)
        }}
      >
        Add Criteria
      </div>

      <div
        className={
          active === 3
            ? 'jobs-sidebar-elem jobs-sidebar-active'
            : `jobs-sidebar-elem`
        }
        onClick={() => {
          history.push(`/career-module/add-additional-questions/${jobid}`)
        }}
      >
        Additional Questions
      </div>

      <div
        className={
          active === 4
            ? 'jobs-sidebar-elem jobs-sidebar-active'
            : `jobs-sidebar-elem`
        }
        onClick={() => {
          history.push(`/career-module/preview-job-description/${jobid}`)
        }}
      >
        Preview
      </div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      <div
        className='jobs-sidebar-elem'
      ></div>
      
    </div>
  )
}

export default Sidebar2