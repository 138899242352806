import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import Cookies from 'universal-cookie'
import csv from '../../../../assets/LabsSampleStudents.csv'
import { Button } from 'reactstrap'
const configs = require('../../../../config/configs.js')

const CsvCard = ({ id, setCsvBlock, isLoading, setIsLoading }) => {
  const [file, setFile] = useState('')
  const cookies = new Cookies()
  useEffect(() => {
    if (!id) {
      alert('No lab id found please contact labs team')
      setCsvBlock('false')
    }
  }, [id, setCsvBlock])

  const handleFile = (e) => {
    let file = e.target.files[0]
    if (file === undefined) return
    setFile(file)
  }

  const submitHanlder = (e) => {
    e.preventDefault()

    try {
      const assignLabs = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }
        setIsLoading(true)
        const data = new FormData()
        data.append('file', file)
        data.append('request_id', id)

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/import_students`,
          data,
          config
        )

        if (res) {
          if (res.status === 200) {
            alert('Lab Assigned')
            setCsvBlock(false)
          } else {
            alert(res.data.message)
          }
        }
        setIsLoading(false)
      }
      assignLabs()
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <div className="labs-add-student-card-main">
      <div className="labs-add-student-card-content">
        <div className="labs-add-student-card-header">
          <span>Add Student</span>

          <Image
            src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
            onClick={() => setCsvBlock(false)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <hr />
        <form onSubmit={(e) => submitHanlder(e)}>
          <Row>
            <hr />
            <Col md={12}>
              <Row className="labs-create-new-form">
                <Col md={2}>
                  <div>
                    <b style={{ fontSize: '12px' }}>Upload CSV:</b>
                  </div>
                </Col>
                <Col md={10}>
                  <input
                    type="file"
                    name="file"
                    onChange={handleFile}
                    accept=".csv"
                    className=""
                    required={true}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12} className="labs-note" style={{ textAlign: 'center' }}>
              <b>Note:</b> Input either Email/Username in the csv
            </Col>
            <Col md={12}>
              <div className="labs-create-new-form">
                <input
                  type="text"
                  name="req_id"
                  value={id}
                  className="labs-create-new-form-text_input"
                  required={true}
                  hidden={true}
                  disabled
                />
              </div>
            </Col>

            <Col md={6}>
              <button
                className="labs-table-btn-1"
                type="submit"
                disabled={isLoading}
              >
                Add Student
              </button>
            </Col>
            <Col md={6}>
              <Button
                color="primary"
                className="p-3"
                style={{ width: '100%', height: '100%', borderRadius: '8px' }}
                href={csv}
                target="_blank"
              >
                Download Sample CSV
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default CsvCard
