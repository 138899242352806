import React, { useEffect, useState,Fragment } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import './GradebookModal.scss'

import TooltipItem from '../.././PageComponents/ManageGradebookAndCertification/PreviewGradebook/TooltipItem.js'

import { IconButton, Tooltip } from '@mui/material'

function GradebookModal(props) {
  const [modalOpen, setModalOpen] = useState(false)
  const [grdData, setGrdData] = useState({})
  const [enableCategory, setEnableCategory] = useState({})
  const [elecNameMap, setElecNameMap] = useState({})
  const [data, setData] = useState({})
  const [grade, setGrade] = useState({})
  const [grades, setGrades] = useState()
  const [assignmentData, setAssignmentData] = useState()
  const [quizData, setQuizData] = useState([])
  const [loading, setLoading] = useState(false)
  const [gradebookLoaded, setGradebookLoaded] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [studentGradebook, setStudentGradebook] = useState([])
  const [collapseRow, setCollapseRow] = useState({})
  const [grdTotalPerc, setGrdTotalPerc] = useState({});
  const [allAssessmentPublished, setAllAssessmentPublished] = useState(false);

  const [grdPlsStatus, setGrdPlsStatus] = useState({})
  const [showWeightage, setShowWeightage] = useState(false)
  const [showPassMarks, setShowPassMarks] = useState(false)
  const [showPlcStatus, setShowPlcStatus] = useState(false)
  const [placementEnable, setPlacementEnable] = useState(false)
  const [columnSpan, setColumnSpan] = useState(false)
  const [gradebookType, setGradebookType] = useState(1)
  const [isElec, setIsElec] = useState(false)
  const [gradebookSkillTags, setGradebookSkillTags] = useState({})
  const [skillNameMap, setskillNameMap] = useState({})
  const [skillTagPresent, setSkillTagPresent] = useState(false)

  const [showPlcPassStatus, setShowPlcPassStatus] = useState(
    'Congratulations! You are eligible for Placement Criteria'
  )
  const [showPlcFailStatus, setShowPlcFailStatus] = useState(
    'You are not eligible for the Placement.  '
  )
  const [plcPassStatusText, setPlcPassStatusText] = useState('PASS')
  const [plcFailStatusText, setPlcFailStatusText] = useState('FAIL')
  const [showContentStatus, setShowContentStatus] = useState(false)
  const [totalWeightAssignedP, setTotalWeightAssignedP] = useState();

  console.log({ props })

  useEffect(() => {
    setGrdData(props.grdData)
    setEnableCategory(props.enableCategory)
    setElecNameMap(props.elecNameMap)
    setGrade(props.tgrade)
    setData(props.adata)
    // setAllAssessmentPublished(res.data.data.all_assessment_publish);
    // setTotalWeightAssignedP(res.data.data.totalWeightAssignedP);

    if (props.courseData && props.grdData) {
      setShowContentStatus(props.courseData.show_content_status)
      setIsElec(props.courseData.elec_level_index)
      setShowPassMarks(props.courseData.show_pass_marks)
      setShowWeightage(props.courseData.show_weightage)
      setGrdPlsStatus(props.grdData.plcStaus)
      let pls_status = props.courseData.plac_type ? props.courseData.plac_type.gradebook_show_plcmnt_stts:false
      setShowPlcStatus(pls_status)

      let passStatus = props.courseData.plac_type
        ? props.courseData.plac_type.plc_pass_text
        : "Congratulations! You are eligible for Placement Criteria";
      let failStatus = props.courseData.plac_type
        ? props.courseData.plac_type.plc_fail_text
        : "You are not eligible for the Placement.  ";
      let plcpassStatus = props.courseData.plac_type
        ? props.courseData.plac_type.plc_pass_status_text
        : "PASS";
      let plcfailStatus = props.courseData.plac_type
        ? props.courseData.plac_type.plc_fail_status_text
        : "FAIL";

      setPlcPassStatusText(plcpassStatus);
      setPlcFailStatusText(plcfailStatus);
      setShowPlcFailStatus(failStatus);
      setShowPlcPassStatus(passStatus);
      setPlacementEnable(props.courseData.plcmnt_enable)
      setGrdTotalPerc(props.grdData.grd_total_perc);
      setStudentGradebook([...props.grdData.placementGradebookCategory])


    }



  }, [])

  useEffect(() => {
    if (!placementEnable) {
      if (showContentStatus) {
        if (showPassMarks && showWeightage) {
          setColumnSpan(6);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(4);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    } else {
      if (showContentStatus) {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(3);
        }
      } else {
        if (showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(5);
        } else if (showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else if (showPassMarks && !showWeightage && !showPlcStatus) {
          setColumnSpan(3);
        } else if (!showPassMarks && showWeightage && showPlcStatus) {
          setColumnSpan(6);
        } else if (!showPassMarks && showWeightage && !showPlcStatus) {
          setColumnSpan(4);
        } else if (!showPassMarks && !showWeightage && showPlcStatus) {
          setColumnSpan(4);
        } else {
          setColumnSpan(2);
        }
      }
    }
  }, [placementEnable, showWeightage, showPassMarks, showPlcStatus])

  const handleClick = (index, data) => {
    let collapserow = collapseRow;

    if (collapserow[data]) {
      collapserow[data] = false;
      setCollapseRow({ ...collapserow });
    } else {
      collapserow[data] = true;
      setCollapseRow({ ...collapserow });
    }
  };


  return (
    <div>
      <>
        <Button
          color="green"
          onClick={() => {
            setModalOpen(true)
          }}
        >
          Show
        </Button>
        <Modal
          style={{ height: 'fit-content', margin: 'auto', marginTop: 'auto' }}
          open={modalOpen}
        >
          <Modal.Header>Gradebook</Modal.Header>
          <Modal.Content
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >


          {studentGradebook &&
            enableCategory &&
            studentGradebook.map((grd, index) => {
              return (
                <div key={index}>
                  {grd.length == 0 ? (
                    <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                      <div
                        className="row no-margin gradeBlock"
                        style={{
                          display: "block",
                          width: "100vw",
                          paddingTop: "8vh",
                          flex: "0 1 100vw",
                          maxWidth: "100vw",
                        }}
                      >
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec
                                ? elecNameMap[grd.elec_id]
                                  ? elecNameMap[grd.elec_id]
                                  : "Core Track"
                                : ""}
                            </span>
                          </h2>

                          <span className="my-grade">
                            {!grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />
                        <div style={{ textAlign: "center" }}>
                          <img
                            src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                            alt="Empty Gradebook"
                          />
                          <br />
                          <br />
                          <span>
                            There are no quizzes and assignments to show at this
                            moment.
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>
                      <div
                        className="row no-margin gradeBlock gradeBlockPadding"
                        style={{ display: "block" }}
                      >
                       
                        <div className="col-12" style={{ padding: 0 }}>
                          <h2
                            style={{
                              color: "#055646",
                              fontWeight: 600,
                              fontSize: "20px",
                              lineHeight: "24px",
                            }}
                          >
                            <span style={{ borderBottom: "2px solid #055646" }}>
                              {isElec
                                ? elecNameMap[grd.elec_id]
                                  ? elecNameMap[grd.elec_id]
                                  : "Core Track"
                                : ""}
                            </span>
                      
                          </h2>

                          <span className="my-grade">
                            {!isElec && !grd.elec_id && "My Grades"}
                          </span>
                        </div>
                        <hr />

                        <div className="flexRow">
                          <table
                            className="table table-bordered gradeTooltip"
                            style={{ minWidth: "700px" }}
                          >
                            <thead className="th-bg-clr">
                              <tr>
                                <th className="t-center th-fnt">Category</th>
                                <th className="t-center th-fnt">
                                  Marks Scored
                                </th>
                                <th className="t-center th-fnt">
                                  Total Marks{" "}
                                </th>
                                {showPlcStatus && placementEnable && (
                                  <th className="t-center th-fnt">
                                    Placement Eligibility Cutoff &nbsp;
                                    <TooltipItem
                                      style={{ backgroundColor: "none" }}
                                      id={"namePopup1" + index}
                                      batch="Sum of scores of all the assessment  should be greater than or equal to the Placement cut off score to be eligible for placement."
                                    />
                                  </th>
                                )}
                                {showPassMarks && !placementEnable && (
                                  <th className="t-center th-fnt">
                                    Passing Marks
                                  </th>
                                )}
                                {showPassMarks &&
                                  placementEnable &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">
                                      Passing Marks
                                    </th>
                                  )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Assigned Weightage %
                                  </th>
                                )}
                                {showWeightage && (
                                  <th className="t-center th-fnt">
                                    Weighted % Scored
                                  </th>
                                )}
                                {placementEnable && showPlcStatus && (
                                  <th className="t-center th-fnt">
                                    Placement Status
                                  </th>
                                )}
                                {!placementEnable && showContentStatus && (
                                  <th className="t-center th-fnt">Status</th>
                                )}
                                {placementEnable &&
                                  showContentStatus &&
                                  !showPlcStatus && (
                                    <th className="t-center th-fnt">Status</th>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {grd.catg &&
                                grd.catg.map((category, index) => {
                                  return (
                                    <Fragment key={`${index}${category.name}`}>
                                      {category.dids &&
                                        category.dids.length > 0 && (
                                          <>
                                            {" "}
                                            <tr
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleClick(
                                                  index,
                                                  `${index}${category.name}`
                                                )
                                              }
                                            >
                                              <td className="bg-white-clr tr-fnt">
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  {" "}
                                                  <div>
                                                    {category.name
                                                      ? category.name
                                                      : "-"}
                                                  </div>{" "}
                                                  <div>
                                                    {" "}
                                                    <img
                                                      src={
                                                        collapseRow[
                                                          `${index}${category.name}`
                                                        ]
                                                          ? "https://webcdn.imarticus.org/Pegasus/uparrow.svg"
                                                          : "https://cdn.pegasus.imarticus.org/Gradebook/dropdown.svg"
                                                      }
                                                    ></img>
                                                  </div>
                                                </div>

                                              </td>

                                              <td className="bg-white-clr tr-fnt">
                                                {category.marks_scored || category.marks_scored == 0
                                                  ? category.marks_scored
                                                  : "-"}
                                              </td>
                                              <td className="bg-white-clr tr-fnt">
                                                {category.total_marks || category.total_marks == 0
                                                  ? category.total_marks
                                                  : "-"}
                                              </td>
                                              {showPlcStatus &&
                                                placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.plac_cutoff || category.plac_cutoff == 0
                                                      ? category.plac_cutoff
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                !placementEnable && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.pass_marks || category.pass_marks == 0
                                                      ? category.pass_marks
                                                      : "-"}
                                                  </td>
                                                )}
                                              {showPassMarks &&
                                                placementEnable &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    -
                                                  </td>
                                                )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weight || category.weight == 0
                                                    ? category.weight
                                                    : "-"}
                                                </td>
                                              )}
                                              {showWeightage && (
                                                <td className="bg-white-clr tr-fnt">
                                                  {category.weightedPView || category.weightedPView == 0
                                                    ? category.weightedPView
                                                    : "-"}
                                                </td>
                                              )}

                                              {placementEnable &&
                                                showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.status
                                                      ? category.status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {!placementEnable &&
                                                showContentStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                              {placementEnable &&
                                                showContentStatus &&
                                                !showPlcStatus && (
                                                  <td className="bg-white-clr tr-fnt">
                                                    {category.all_cagetory_content_pass_status
                                                      ? category.all_cagetory_content_pass_status
                                                      : "-"}
                                                  </td>
                                                )}
                                            </tr>
                                            {collapseRow[
                                              `${index}${category.name}`
                                            ] && category.dids ? (
                                              <>
                                                <tr>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    My Assessments
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Marks Scored
                                                  </td>
                                                  <td
                                                    className="bg-blue-clr"
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: 600,
                                                      fontSize: "16px",
                                                    }}
                                                  >
                                                    Total Marks
                                                  </td>
                                                  {showPassMarks && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Pass Marks
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assigned Weight%
                                                    </td>
                                                  )}
                                                  {showWeightage && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Weighted % Scored
                                                    </td>
                                                  )}

                                                  {showContentStatus && (
                                                    <td
                                                      className="bg-blue-clr"
                                                      style={{
                                                        textAlign: "center",
                                                        fontWeight: 600,
                                                        fontSize: "16px",
                                                      }}
                                                    >
                                                      Assessment Status
                                                    </td>
                                                  )}
                                                </tr>
                                                {category.dids.map((did) => {
                                                  return (
                                                    <tr>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.name
                                                          ? did.name
                                                          : "-"}
                                                      </td>
                                                      {/* <td className="bg-blue-clr tr-fnt">
                                                  {did.marks ? did.marks:'-'}
                                                </td> */}
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {/* {did.status
                                                          ? did.status === 1
                                                            ? `${did.marks}`
                                                            : did.status === 2
                                                              ? "Under Evaluation"
                                                              : "Absent"
                                                          : did.marks !== "-"
                                                            ? `${did.marks}`
                                                            : "Absent"} */}
                                                        {did.marks || did.marks == 0
                                                          ? did.marks
                                                          : "-"}
                                                      </td>
                                                      <td className="bg-blue-clr tr-fnt">
                                                        {did.total_marks || did.total_marks == 0
                                                          ? did.total_marks
                                                          : "-"}
                                                      </td>

                                                      {showPassMarks && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.pasmrk || did.pasmrk == 0
                                                            ? did.pasmrk
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight || did.weight == 0
                                                            ? did.weight
                                                            : "-"}
                                                        </td>
                                                      )}
                                                      {showWeightage && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.weight || did.weight == 0
                                                            ? did.weightedPView
                                                            : "-"}
                                                        </td>
                                                      )}

                                                      {showContentStatus && (
                                                        <td className="bg-blue-clr tr-fnt">
                                                          {did.showStatus
                                                            ? did.showStatus
                                                            : "-"}
                                                        </td>
                                                      )}
                                                    </tr>
                                                  );
                                                })}
                                              </>
                                            ) : null}
                                          </>
                                        )}
                                    </Fragment>
                                  );
                                })}
                              {/* Render assignment data */}

                              <tr>
                                <th
                                  className="t-center th-fnt"
                                  colSpan={columnSpan} // ----------------------
                                  style={{ textAlign: "left" }}
                                >
                                  Total Grade (%)
                                </th>
                                <th
                                  className="t-center th-fnt total"
                                  colSpan="1"
                                  style={{ position: "relative" }}
                                >
                                  {grdTotalPerc[grd.elec_id]
                                    ? grdTotalPerc[grd.elec_id].toFixed(2)
                                    : grdTotalPerc["FOUNDATION"]
                                    ? grdTotalPerc["FOUNDATION"].toFixed(2)
                                    : "-"}
                                  % &nbsp;&nbsp;
                                  {totalWeightAssignedP < 100 && (
                                    <TooltipItem
                                      id="namePopupGrade2"
                                      batch=" You will be able to see your final
                                 Grade for the course once all your
                                 assessment scores have been
                                 published."
                                    />
                                  )}
                                </th>
                              </tr>
                              {showPlcStatus &&
                                placementEnable &&
                                allAssessmentPublished && (
                                  <>
                                    {" "}
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage &&
                                          // showPassMarks &&
                                          // placementEnable
                                          //   ? 5
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 4
                                          //   : placementEnable
                                          //   ? 3
                                          //   : 2
                                          columnSpan
                                        } // ----------------------
                                        style={{
                                          textAlign: "left",
                                          weight: 60,
                                          size: "20px",
                                        }}
                                      >
                                        Placement Status
                                      </th>
                                      <th
                                        className="t-center th-fnt total"
                                        colSpan="1"
                                        style={{ position: "relative" }}
                                      >
                                        {/* {grdPlsStatus[grd.elec_id]
                                    ? (grdPlsStatus[grd.elec_id] ? 'PASS' :'FAIL')
                                    : grdPlsStatus["FOUNDATION"]
                                    ? (grdPlsStatus["FOUNDATION"]?'PASS' :'FAIL')
                                    : "-"} */}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? plcPassStatusText
                                            : plcFailStatusText)}{" "}
                                      </th>
                                    </tr>
                                    <tr style={{ backgroundColor: "white" }}>
                                      <th
                                        className="t-center th-fnt"
                                        colSpan={
                                          // showWeightage && showPassMarks
                                          //   ? 6
                                          //   : (!showWeightage && showPassMarks) ||
                                          //     (showWeightage && !showPassMarks)
                                          //   ? 5
                                          //   : 4
                                          columnSpan + 1
                                        } // ----------------------
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        {grd.elec_id &&
                                          (grdPlsStatus[grd.elec_id]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                        {!grd.elec_id &&
                                          (grdPlsStatus["FOUNDATION"]
                                            ? `${showPlcPassStatus}`
                                            : `${showPlcFailStatus}`)}
                                      </th>
                                    </tr>
                                  </>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                     
                   
                        
                    </div>
                  )}
                </div>
              );
            })}

            {grdData && grdData.contentElecMap &&
              !enableCategory &&
              Object.keys(grdData.contentElecMap).map((grd, index) => {
                return (
                  <div key={index}>
                    {grdData.contentElecMap[grd] && grdData.contentElecMap[grd].length == 0 ? (
                      <div className="rvm-v1-cont-ctnr bottomRowGradebook">
                        <div
                          className="row no-margin gradeBlock"
                          style={{
                            display: 'block',
                            width: '100vw',
                            paddingTop: '8vh',
                            flex: '0 1 100vw',
                            maxWidth: '100vw',
                          }}
                        >
                          <div className="col-12" style={{ padding: 0 }}>
                            <h2
                              style={{
                                color: '#055646',
                                fontWeight: 600,
                                fontSize: '20px',
                                lineHeight: '24px',
                              }}
                            >
                              {/* {elecNameMap[grd] &&  <> <span style={{ borderBottom: "2px solid #055646" }}>
                           
                           </span>
                           </>} */}
                              <span className="my-grade">{'My Grades'}</span>
                            </h2>
                          </div>
                          <hr />
                          <div style={{ textAlign: 'center' }}>
                            <img
                              src="http://cdn.pegasus.imarticus.org/images/gradebook_empty.svg"
                              alt="Empty Gradebook"
                            />
                            <br />
                            <br />
                            <span>
                              There are no quizzes and assignments to show at this
                              moment.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className={`rvm-v1-cont-ctnr bottomRowGradebook`}>

                          <div
                            className="row no-margin gradeBlock gradeBlockPadding"
                            style={{ display: 'block' }}
                          >
                            <div className="col-12" style={{ padding: 0 }}>
                              <h2
                                style={{
                                  color: '#055646',
                                  fontWeight: 600,
                                  fontSize: '20px',
                                  lineHeight: '24px',
                                }}
                              >
                                <span
                                  style={{ borderBottom: '2px solid #055646' }}
                                >
                                  {isElec
                                    ? elecNameMap[grd]
                                      ? elecNameMap[grd]
                                      : 'Core Track'
                                    : ''}
                                </span>
                              </h2>

                              {!grdData.elec_enable && (
                                <span className="my-grade">{'My Grades'}</span>
                              )}
                            </div>
                            <hr />

                            <div className="flexRow">
                              <table
                                className="table table-bordered gradeTooltip"
                                style={{ minWidth: '700px' }}
                              >
                                <thead className="th-bg-clr">
                                  <tr>
                                    <th className="t-center th-fnt">
                                      Assessment/Quiz
                                    </th>
                                    <th className="t-center th-fnt">
                                      Actual Marks
                                    </th>
                                    <th className="t-center th-fnt">
                                      Total Marks
                                    </th>

                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Assigned Weightage%
                                      </th>
                                    )}
                                    {showPassMarks && (
                                      <th className="t-center th-fnt">
                                        Passing Marks
                                      </th>
                                    )}
                                    {showWeightage && (
                                      <th className="t-center th-fnt">
                                        Weighted % Scored

                                      </th>
                                    )}
                                    {showContentStatus && <th className="t-center th-fnt">  Assessment Status</th>}

                                  </tr>
                                </thead>
                                <tbody>
                                  {/* Render assignment data */}
                                  {grdData.contentElecMap[grd].map((ag) => (
                                    <tr key={ag.nm}>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.name ? ag.name : '-'}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.marks || ag.marks == 0 ? ag.marks : "-"}
                                      </td>
                                      <td className="bg-white-clr tr-fnt">
                                        {ag.total_marks || ag.total_marks == 0? ag.total_marks : ag.maxmrk}
                                      </td>

                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weight || ag.weight == 0 ? ag.weight : '-'}%
                                        </td>
                                      )}
                                      {showPassMarks && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.pasmrk || ag.pasmrk == 0 ? ag.pasmrk : '-'}
                                        </td>
                                      )}
                                      {showWeightage && (
                                        <td className="bg-white-clr tr-fnt">
                                          {ag.weightedPView || ag.weightedPView == 0 ? ag.weightedPView : '-'}%
                                        </td>
                                      )}
                                      {showContentStatus && <td className="bg-white-clr tr-fnt">
                                      {ag.showStatus || ag.showStatus == 0 ? ag.showStatus : "-"}

                                      </td>}
                                    </tr>
                                  ))}

                                  <tr>
                                    <th
                                      className="t-center th-fnt"
                                      // colSpan={showWeightage ? "3" : "2"}
                                      colSpan={showContentStatus ?
                                        showWeightage && showPassMarks
                                          ? 6
                                          : showWeightage && !showPassMarks
                                            ? 5
                                            : !showWeightage && showPassMarks
                                              ? 4
                                              : 3 :
                                        showWeightage && showPassMarks
                                          ? 5
                                          : showWeightage && !showPassMarks
                                            ? 4
                                            : !showWeightage && showPassMarks
                                              ? 3 : 2
                                      }// ----------------------
                                      style={{ textAlign: 'left' }}
                                    >
                                      Total Grade (%)
                                    </th>
                                    <th
                                      className="t-center  total"
                                      colSpan="1"
                                      style={{ position: 'relative' }}
                                    >
                                       {grdTotalPerc[grd]
                                      ? grdTotalPerc[grd].toFixed(2)
                                      : "-"} %&nbsp;&nbsp;
                                      {/* {totalWeightAssignedP < 100 && (
                                        <TooltipItem
                                          id="namePopupGrade25"
                                          batch=" You will be able to see your final
                                    Grade for the course once all your
                                    assessment scores have been
                                    published."
                                        />
                                      )} */}
                                    </th>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}

            <div>
              You will be able to see the final Grades for the course once all
              the assessment scores have been published.
            </div>

          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              onClick={(e) => { e.stopPropagation(); setModalOpen(false) }}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal></>
    </div>
  )
}

export default GradebookModal
