import React, { useEffect, useState } from 'react'
import Select from "react-select"
import PlacementHeader from './Components/TopBar'
import Sidebar from './Components/Sidebar'
import './PlacementPortal.scss'
import momentjs from 'moment-timezone'
import { connect } from 'react-redux'
import '../../Components/style/assignmentDeadline.css'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TablePagination,
  Autocomplete,
  TextField,
  ToggleButton, ToggleButtonGroup
} from '@mui/material'
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
} from 'reactstrap'
import { placementSidebarMap, JOB_STATUS } from "../../constants/constant";
import {
  fetchPlacementCourse,
} from '../../actions/jobAction.js'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Loader } from 'semantic-ui-react'
import moment from 'moment'
import { LOCATION_CHANGE } from '../../actions/actiontypes.js'
import PDFReader from "../../Components/PDFReader/PDFReader";

const configs = require('../../config/configs.js')

const ApplicationStatus = (props) => {
  const [requests, setRequests] = useState([])
  const [coursepages, setCoursepages] = useState([])
  const [selectedCourse, setSelectedCourse] = useState({})
  const [selectedBatch, setSelectedBatch] = useState({})
  const [selectedCoursepage, setSelectedCoursepage] = useState('')
  const [batches, setBatches] = useState([])
  const [locations, setLocations] = useState([])
  const [courses, setCourses] = useState([])
  const [validFrom, setValidFrom] = useState('')
  const [validTill, setValidTill] = useState('')
  const [searchJobKey, setSearchJobKey] = useState('')
  const [locationName, setLocationName] = useState('')
  const [locationId, setLocationId] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [hoverImage, setHoverImage] = useState(false)
  const [openJobCreate, setOpenJobCreate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [searchOptions, setSearchOptions] = useState([{ value: 'company_name', label: 'Search By Company Name' }, { value: 'opp_id', label: 'Search By Opportunity ID' }])
  const [selectedSearchOptions, setSelectedSearchOptions] = useState('')

  const [selectedJobTitle, setSelectedJobTitle] = useState('All')
  const [jobTitles, setJobTitles] = useState([])
  const [resumeURL, setResumeURL] = useState("");
  const [jobStatuses, setJobStatuses] = useState([{
    _id: '',
    name: 'All'
  }, { _id: 3, name: 'Live' }, { _id: 4, name: 'Complete' }, { _id: 5, name: 'Closed' }])
  const [companyNames, setCompanyNames] = useState([])
  const [selectedCompany, setSelectedCompany] = useState('All')
  const [jobStatus, setJobStatus] = useState({
    _id: '',
    name: 'All'
  })
  const [data, setData] = useState([])
  const [selectedLearner, setSelectedLearner] = useState({})
  const [selectedLearnerResume, setSelectedLearnerResume] = useState([])
  const [openResumeView, setOpenResumeView] = useState(false)

  const courseChanged = (course) => {
    if (String(selectedCourse?._id) !== String(course._id)) {
      setSelectedCourse(course)
      setSelectedBatch('')
      setBatches([])
      setLocationName('')
      setData([])
    }
  }

  const batchChanged = (batch) => {
    if (String(selectedBatch?._id) !== String(batch._id)) {
      setSelectedBatch(batch)
    }
  }
  const handleResumeModalClose = () => {
    setOpenResumeView(false)
  }

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  let serialNum = 1
  useEffect(() => {
    console.log({ abc: props.permissions })
    // if(selectedCourse && Object.keys(selectedCourse).length > 0){
    let searchField = selectedSearchOptions ? selectedSearchOptions.value : ''
    getAppStatusData(selectedCourse._id, selectedBatch._id)
    // }

  }, [selectedCourse, locationId, selectedBatch, validTill, selectedCompany, jobStatus._id, selectedJobTitle, refresh, page, rowsPerPage])

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)

    fetchPlacementCourse().then((res) => {
      if (res && res.data) {
        // if ('courses' in res.data) {
        //   setCourses(res.data.courses)
        // }

        let tmp_data =
          res.data.courses && res.data.courses
            ? res.data.courses
            : []
        let result = []
        for (let d = 0; d < tmp_data.length; d++) {
          if (tmp_data[d].name) {
            result.push({
              name: tmp_data[d].name,
              _id: tmp_data[d]._id,
            })
          }
        }
        console.log(res.data.courses)
        console.log({ coursessssss: tmp_data })
        setCourses(tmp_data)
      }
      // setLoading(false)
    })
    const getAllLocations = () => {
      return new Promise((resolve, reject) => {
        var getData = {
          url: `${configs.routes.BASE_URL_DEVELOPMENT
            }/cfpanelGeneric/get_all_course_locations?aid=${cookies.get(
              'aid'
            )}&pid=${cookies.get('pid')}`,
          method: 'GET',
          headers: {
            'x-access-token': cookies.get('at'),
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
        Axios(getData)
          .then((res) => {
            let tmp_data =
              res.data.data && res.data.data.allLocations
                ? res.data.data.allLocations
                : []
            let result = []
            for (let d = 0; d < tmp_data.length; d++) {
              if (tmp_data[d].name) {
                result.push({
                  name: tmp_data[d].name,
                  _id: tmp_data[d]._id,
                })
              }
            }
            result.unshift({ name: 'All', _id: null })
            setLocations(result)
            setIsLoading(false)
            resolve(result)
          })
          .catch((err) => {
            setIsLoading(false)
            console.error(err)
            reject(err)
          })
      })
    }
    getAllLocations()

  }, [refresh, page, rowsPerPage])

  const getAllBatches = (crs_pg_id, location_id) => {
    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        a_id: cookies.get('aid'),
      }
      if (location_id != 'ALL') {
        data['location_id'] = location_id
      }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelCourse/ls_btch`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let tmp_data =
            res.data.data && res.data.data.courses ? res.data.data.courses : []
          let result = []
          for (let d = 0; d < tmp_data.length; d++) {
            if (tmp_data[d].btch_name) {
              result.push({
                name: tmp_data[d].btch_name,
                _id: tmp_data[d]._id,
              })
            }
          }
          // if (!result.length) {
          //   alert('No batches found at this location')
          // }
          setBatches(result)
          setIsLoading(false)
          resolve(result)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  const handleDateInputChnage = (e) => {
    const [key, value] = [e.target.name, e.target.value]

    if (e.target.name == 'validFrom') {
      setValidFrom(e.target.value)
    }
    if (e.target.name == 'validTill') {
      setValidTill(e.target.value)
    }

  }

  const getAppStatusData = (crs_pg_id, cid) => {
    setIsLoading(true)

    const cookies = new Cookies()
    return new Promise((resolve, reject) => {
      let data = {
        crs_pg_id: crs_pg_id,
        cid: cid,
        loc_id: locationId,
        company_name: selectedCompany,
        job_title: selectedJobTitle,
        a_id: cookies.get('aid'),
        searchField: selectedSearchOptions.value,
        searchVal: searchJobKey,
        job_status: jobStatus._id,
        page: page,
        rowsPerPage: rowsPerPage
      }
      // if (location_id) {
      //   data['location_id'] = location_id
      // }
      var getData = {
        url: `${configs.routes.BASE_URL_DEVELOPMENT}/cfpanelPlacement/get_learnerappstatus_data`,
        method: 'POST',
        data: data,
        headers: {
          'x-access-token': cookies.get('at'),
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
      Axios(getData)
        .then((res) => {
          let result = []
          console.log(res.data.data)
          let tmp_data =
            res.data.data && res.data.data.jobData ? res.data.data.jobData : []
          let tmp_company_name = res.data.data && res.data.data.companyName ? res.data.data.companyName : []
          let tmp_job_title = res.data.data && res.data.data.jobTitle ? res.data.data.jobTitle : []
          let total_count = res.data.data && res.data.data.totalJobs ? res.data.data.totalJobs : 0
          if (tmp_data.length == 0) {
            // window.alert("No Jobs found for selected Batch & Course")
            setData([])
          }
          tmp_company_name.unshift('ALL')
          tmp_job_title.unshift('ALL')
          setData(tmp_data)
          setCompanyNames(tmp_company_name)
          setJobTitles(tmp_job_title)
          setTotalCount(total_count)
          resolve(result)
          setIsLoading(false)
        })
        .catch((err) => {
          console.log('inside get data')
          setIsLoading(false)
          console.error(err)
          reject(err)
        })
    })
  }

  useEffect(() => {
    const cookies = new Cookies()

    if (selectedCourse && Object.keys(selectedCourse).length > 0) {
      getAllBatches(selectedCourse._id, locationId)
    }

  }, [selectedCourse, locationId, refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  const locationChanged = (locationName, locationId) => {
    setLocationName(locationName)
    setLocationId(locationId)
  }
  const jobStatusChanged = (jobStatus) => {
    setJobStatus(jobStatus)
  }

  const jobTitleChanged = (jobTitle) => {
    setSelectedJobTitle(jobTitle)
  }

  const companyNameChanged = (compName) => {
    setSelectedCompany(compName)
  }

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  const handleResumeModalOpen = (aid) => {

    const selectedResume = data.find((item) => item.aid === aid);

    if (selectedResume) {
      setSelectedLearner(selectedResume)
      setSelectedLearnerResume(selectedResume.learnerResume);
      console.log('Opening Resume Modal');
      console.log('Selected Resume:', selectedResume.learnerResume);
    }
    setOpenResumeView(true)
  };

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <div className='placement-portal' style={{ overflow: "hidden" }}>
      <>
        <Sidebar active={2} />
        <div className="labs-main">
          <PlacementHeader breadCrumbs={[
            { name: "Career Services", link: "/" },
          ]}
            active={2} />
          <div className="labs-content">
            <div style={{ borderBottom: '0.75px solid rgba(33, 42, 57, 0.25)', paddingBottom: '16px' }}>
              <Row>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Course Name</p>
                  <Autocomplete
                    disableClearable
                    value={selectedCourse && selectedCourse.name}
                    onChange={(event, course) => {
                      courseChanged(course)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={courses}
                    disabled={courses.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Course Name"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                {/* Courses */}
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Training Location</p>
                  <Autocomplete
                    disableClearable
                    value={locationName}
                    onChange={(event, location) => {
                      locationChanged(location.name, location._id)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={locations}
                    disabled={locations.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Training Location"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Batch</p>
                  <Autocomplete
                    disableClearable
                    value={selectedBatch && selectedBatch.name}
                    onChange={(event, batch) => {
                      batchChanged(batch)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={batches}
                    disabled={batches.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Batch"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>

                <Col md={3} >
                  <div className="row" style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: '10px', margin: "0px", marginLeft: "10px", paddingBottom: "4px" }}>From</p>
                    <input
                      type="date"
                      className="labs-create-new-form-date_input"
                      name="validFrom"
                      value={validFrom}
                      required={true}
                      onChange={(event) => {
                        handleDateInputChnage(event)
                      }}
                    // min={this.state.currentFormattedDate}
                    // max={this.state.finalFormattedDate}
                    />
                  </div>

                </Col>
                <Col md={3} >
                  <div className="row" style={{ display: "flex", flexDirection: "column" }}>
                    <p style={{ fontSize: '10px', margin: "0px", marginLeft: "10px", paddingBottom: "4px" }}>To</p>
                    <input
                      type="date"
                      className="labs-create-new-form-date_input"
                      name="validTill"
                      value={validTill}
                      required={true}
                      onChange={(event) => {
                        handleDateInputChnage(event)
                      }}
                      min={validFrom}
                      disabled={validFrom == '' ? true : false}
                    // max={this.state.finalFormattedDate}
                    />
                  </div>
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Company Name</p>
                  <Autocomplete
                    disableClearable
                    value={selectedCompany}
                    onChange={(event, reviewStatus) => {
                      companyNameChanged(reviewStatus)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={companyNames}
                    disabled={data.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Company Name"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Select Job Title</p>
                  <Autocomplete
                    disableClearable
                    value={selectedJobTitle}
                    onChange={(event, reviewStatus) => {
                      jobTitleChanged(reviewStatus)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={jobTitles}
                    disabled={data.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Select Job Title"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
                <Col md={2} className="labs-table-header-2">
                  <p style={{ fontSize: '10px', margin: "0px", paddingBottom: "4px" }}>Job Status</p>
                  <Autocomplete
                    disableClearable
                    value={jobStatus ? jobStatus.name : ''}
                    onChange={(event, reviewStatus) => {
                      jobStatusChanged(reviewStatus)
                    }}
                    getOptionLabel={(batch) => {
                      return batch.name || batch
                    }}
                    getOptionSelected={(option, test) => option.name == test}
                    id="batchSelector"
                    options={jobStatuses}
                    disabled={jobStatuses.length === 0 ? true : false}
                    renderInput={(params) => {
                      return (
                        <TextField
                          // label="Job Status"
                          {...params}
                          variant="outlined"
                          fullWidth
                        />
                      )
                    }}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: '2rem' }}>
                <Col md={8} className="labs-table-header">
                  <p>Application Status</p>
                </Col>
              </Row>
            </div>
            <Row style={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between', marginLeft: "0px" }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="btch-search" style={{ marginRight: '1rem', width: '250px' }}>
                  <Select
                    options={searchOptions}
                    placeholder="Select Search By "
                    value={selectedSearchOptions}
                    onChange={(event) => {
                      setSelectedSearchOptions(event)
                    }}
                    isSearchable={true}
                  />

                </div>
                <div className="btch-search">
                  <input
                    placeholder="Search Application"
                    value={searchJobKey}
                    onChange={(e) => setSearchJobKey(e.target.value)}
                    className="form-control as-dl-search full-height"
                    ref={(input) =>
                      input && searchJobKey && input.focus()
                    }
                    style={{
                      height: "100% !important"
                    }}
                  />
                </div>
                <Col md={2}>
                  <button
                    onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                    className="labs-table-btn-3"
                  >
                    Search
                  </button>
                </Col>
                <Col md={1}>
                </Col>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col md={12} style={{ width: '100%' }}>
                  {/* <button
                  onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                  className="labs-table-btn-4"
                >
                  Cancel Job
                </button> */}
                </Col>
              </div>
            </Row>
            {data.length > 0 ? (
              <Row style={{ marginTop: '1rem', marginLeft: "0px" }}>
                <TableContainer component={Paper} style={{ height: '600px' }}>
                  <Table aria-label="custom pagination table" id="batch-data-table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="btch-dt-chk sticky-header-both-zero"
                          scope="col"
                        >
                          S No.
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header-both-first"
                          scope="col"
                        >
                          Opportunity ID
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header-both-second"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Job ID
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header-both-third"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Job Title
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell
                          className="btch-dt-th sticky-header"
                          scope="col"
                          style={{ cursor: 'pointer' }}
                        >
                          Company Name
                          <span
                            style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                          >
                          </span>
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Company HR Name
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Company HR Email ID
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Company HR Mobile No.
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Training Center
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Application Start Date
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Application End Date
                        </TableCell>
                        {/* <TableCell className="btch-dt-th sticky-header" scope="col">
                Drive End Date
                </TableCell> */}
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Job Status
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          No. of Learners Applied
                        </TableCell>
                        <TableCell className="btch-dt-th sticky-header" scope="col">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.map((dt, key) => {
                          return (
                            <TableRow>
                              <TableCell
                                className="btch-dt-chk sticky-first"
                                scope="row"
                              >{serialNum++}</TableCell>
                              <TableCell
                                className="btch-dt-td-app sticky-second"
                                scope="row"
                              >{dt.opportunity_id}</TableCell>
                              <TableCell
                                className="btch-dt-td-app sticky-third"
                                scope="row"
                              >
                                {dt.job_code}
                              </TableCell>
                              <TableCell
                                className="btch-dt-td-app sticky-fourth"
                                scope="row"
                              >
                                {dt.job_title}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {dt.company_name || "-"}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {dt.hr_name}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {dt.hr_email}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {dt.hr_ccod}{dt.hr_mobile}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {dt.training_locations.map(trainingLocation => {
                                  const location = locations.find(loc => loc._id === trainingLocation.loc_id);
                                  console.log({ location })
                                  if (location) {
                                    return (
                                      <div key={trainingLocation._id}>
                                        {location.name}
                                        <br />
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {moment(dt.app_start_time).format("DD-MM-YYYY HH:mm")}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {moment(dt.app_end_time).format("DD-MM-YYYY HH:mm")}
                              </TableCell>
                              {/* <TableCell className="btch-dt-td-app" scope="row">
                            {moment(dt.drive_end_date).format("DD-MM-YYYY HH:mm")}
                            </TableCell> */}
                              <TableCell className="btch-dt-td-app" scope="row">
                                {JOB_STATUS[dt.job_status]}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                {dt.numAppliedCount}
                              </TableCell>
                              <TableCell className="btch-dt-td-app" scope="row">
                                <a href={`/career-services/single-jobapplication-status/${dt._id}`}>View Applications</a>
                              </TableCell>`
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div style={{ marginRight: '256px' }}>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
              </Row>
            ) :
              <div className="job-not-found">
                <img src='https://webcdn.imarticus.org/demo/Group4606.svg'></img>
                <div style={{ marginBottom: '1rem' }}>
                  <br></br>
                  <h5>It's empty here!</h5>
                </div>
                <div>
                  <p style={{ marginLeft: '1rem' }}>First select a course by clicking on</p>
                  <p >"Select Course Name" to view applications here</p>
                </div>
              </div>
            }
            <Modal className="jobs-viewresumemodal" isOpen={openResumeView}>
              <ModalHeader>
                <li className="row">
                  <div className="col-md-8">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {selectedLearner ? `${selectedLearner.name}'s Resume` : "Learner's Resume"}
                    </div>
                  </div>
                  {selectedLearner && selectedLearnerResume && selectedLearnerResume.length > 0 && (
                    <div className='res-dl-btn col-md-4 '>
                      Download Resumes
                    </div>)}
                </li>
              </ModalHeader>

              <ModalBody
                className="thumbModal"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                {selectedLearner && selectedLearnerResume && selectedLearnerResume.length == 0 && (
                  <div>No resume found</div>
                )}
                {selectedLearnerResume
                  .filter((file) => file.resume_type === 2)
                  .map((file, index) => (
                    <div key={index}>
                      <div className="resume-div drop-zone">
                        <li className="row">
                          <div className="col-md-2">
                            <img
                              className="resume-icon-2"
                              src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                            />
                          </div>
                          <div className="col-md-4">
                            <h5 className="resume-heading">
                              Resume {index + 1}
                            </h5>
                          </div>
                          <div className='col-md-3'>
                            <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                          </div>
                          <div className="col-md-1">
                          </div>
                          <div className="col-md-2">
                            <img
                              src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                              className="as-dl-logo-job-2"
                            />
                          </div>
                        </li>
                      </div>
                      <br />
                    </div>
                  ))}

                {selectedLearnerResume
                  .filter((file) => file.resume_type === 1)
                  .map((file, index) => (
                    <div key={index}>
                      <div className="resume-div drop-zone">
                        <li className="row">
                          <div className="col-md-2">
                            <img
                              className="resume-icon-2"
                              src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                            />
                          </div>
                          <div className="col-md-4">
                            <h5 className="resume-heading">
                              Video Resume {index + 1}
                            </h5>
                          </div>
                          <div className='col-md-3'>
                            <p>{moment(file.createdAt).format("DD-MM-YYYY")}</p>
                          </div>
                          <div className="col-md-1">
                          </div>
                          <div className="col-md-2">
                            <a
                              href={file.resume_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                className="as-dl-logo-job-2"
                                alt="View Resume"
                              />
                            </a>
                          </div>
                        </li>
                      </div>
                      <br />
                    </div>
                  ))}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => handleResumeModalClose()}
                >
                  Close
                </Button>
              </ModalFooter>
            </Modal>
            <Modal isOpen={togglePDFDoc} size={"md"} style={{ minwidth: "400px" }}>
              <ModalHeader
                className="resume-prev"
                style={{ paddingRight: "7px", width: "100%" }}
              >
                <div className="row" style={{ justifyContent: "space-between", marginLeft: "12px", marginRight: "12px" }}>
                  <div>Resume Preview </div>
                  <div>
                    <img
                      src="https://webcdn.imarticus.org/Placement/Group926.svg"
                      style={{ paddingRight: "7px" }}
                      onClick={() => setTogglePDFDoc(false)}
                    />
                  </div>
                </div>
              </ModalHeader>
              <ModalBody
                style={{
                  minHeight: "500px",
                  overflowY: "scroll",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                {/* <PDFReader url={resumeURL}></PDFReader> */}
                <iframe
                  src={resumeURL}
                  style={{ width: "100%", height: "100%", border: "none", minHeight: "500px" }}
                  frameBorder="0"
                />
              </ModalBody>
              <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
            </Modal>
          </div>
        </div>
      </>
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(ApplicationStatus)