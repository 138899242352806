import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../../assets/main.css'

import { Row, Col, Button } from 'reactstrap'
import { connect } from 'react-redux'
import { history } from '../../index.js'
import Cookies from 'universal-cookie'
import { RemoveTableData } from './../../actions/feedMonitorDashboardAction'

const cookies = new Cookies()

class Sidebar extends Component {
  constructor() {
    super()
    this.logOut = this.logOut.bind(this)
    this.goBack = this.goBack.bind(this)
  }
  logOut() {
    console.log('LOGGED OUT')
    this.props.RemoveTableData()
    cookies.remove('at')
    history.push('/')
  }
  goBack(){
    console.log("GoBack!")
    window.history.back()
  }

  render() {
    console.log(
      '======================== PROPS IN SIDEBAR :::: ',
      window.location.href,
      ' ========================================='
    )
    let url = window.location.href
    if (url.includes('/details')) {
      let ids = url.split('/details')[1]
      let specIds = ids.split('/')
      console.log(':::Details ::: ', ids.split('/'))
      var crs_pg_id = specIds[2]
      var location_id = specIds[3]
      var course_id = specIds[4]
      var assignment_id = specIds[5]
      var status = specIds[7]
    }
    var at = cookies.get('isLoggedIn')
    var loggedIn = at !== undefined
    return (
      <div className="sidenav">
        <p className="companyname">
          {/* <a className='link' href="/">IMARTICUS</a> */}
          <Link to="/">
            <img
              src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
              alt="logo"
              className="as-dl-logo-hp"
            />
          </Link>
        </p>
        {window.location.href.includes('/details') && (
          <p style={{ marginLeft: '40px', marginTop: '50px' }}>
            <Link
              style={{ fontSize: '18px', color: 'white' }}
              to={`/assignmentpanel?crs_pg_id=${crs_pg_id}&loc_id=${location_id}&course_id=${course_id}&ass_id=${assignment_id}&status=${status}`}
            >
              {' '}
              &lt;&lt; Back
            </Link>
          </p>
        )}
        {/* {console.log("window.location.href.includes('/head')", window.location.href, window.location.href.includes('/head'), window.location.href.includes('/main'))} */}
        {/* {window.location.href.includes('/head') === false && window.location.href.includes('/main') === false && <p onClick={this.goBack} style={{paddingLeft: "10px", marginTop: "25px", color:'white', fontSize: "14px", cursor: "pointer"}}>&lt; Back</p>} */}
        {loggedIn ? (
          <div className="back-button-div">
            <Button
              className="back-button"
              color="danger"
              onClick={() => {
                this.logOut()
              }}
            >
              Log out
            </Button>
          </div>
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  fmpanel: state.fmpanel,
  reducer: state.reducer,
})

export default connect(mapStateToProps, { RemoveTableData })(Sidebar)
