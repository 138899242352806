const initialState = {
  selected_gradstatus: 'ALL',
  selected_activitystatus: 'ALL',
  npscurrent_page: 1,
  page: 0,
  searchcategory: '',
  searchvalue: '',
  selected_batch: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'FETCH_COURSEPAGES':
      return {
        ...state,
        coursepages: action.payload.coursepages,
      }

    case 'FETCH_COURSE_GRAD_STATUS':
      return {
        ...state,
        graddetails: action.payload.graddetails,
      }

    case 'SELECTED_COURSE_PAGE':
      return {
        ...state,
        selected_coursepage: action.payload.selected_coursepage,
      }

    case 'SELECTED_BATCH':
      return {
        ...state,
        selected_batch: action.payload.selected_batch,
      }

    case 'FETCH_NPS_SCORE_DETAIL':
      return {
        ...state,
        npsscore_detail: action.payload.npsscore_detail,
      }

    case 'FETCH_NPS_REPORT':
      return {
        ...state,
        npsreport: action.payload.npsreport,
      }

    case 'ADD_MORE_NPS_REPORT':
      return {
        ...state,
        npsreport: state.npsreport.concat(action.payload.npsreport),
      }

    case 'FETCH_LEARNER_GRAD_REPORT':
      return {
        ...state,
        gradreport: action.payload.gradreport,
      }

    case 'DELETE_LEARNER_GRAD_REPORT':
      return {
        ...state,
        gradreport: '',
      }

    case 'UPDATE_NPSCURRENT_PAGE':
      return {
        ...state,
        npscurrent_page: action.payload.npscurrent_page,
      }

    case 'UPDATE_LReport_CURRENT_PAGE':
      return {
        ...state,
        lpcurrent_page: action.payload.lreport_current_page,
      }

    case 'SELECTED_GRADUATION_STATUS':
      return {
        ...state,
        selected_gradstatus: action.payload.selected_gradstatus,
      }

    case 'SELECTED_ACTIVITY_STATUS':
      return {
        ...state,
        selected_activitystatus: action.payload.selected_activitystatus,
      }

    case 'FETCH_INDIVIDUAL_LEARNER_DETAIL':
      return {
        ...state,
        learnerdata: action.payload.learnerdata,
      }

    case 'DELETE_INDIVIDUAL_LEARNER_DETAIL':
      return {
        ...state,
        learnerdata: '',
      }

    case 'FETCH_INDIVIDUAL_LEARNER_EMAILS':
      return {
        ...state,
        emailsSent: action.payload.learnerdata.emails,
        totalEmailCount: action.payload.learnerdata.totalCount,
      }

    case 'FETCH_INDIVIDUAL_LEARNER_WHATSAPP':
      return {
        ...state,
        whatsappSent: action.payload.learnerdata.whatsapp,
        totalWhatsappCount: action.payload.learnerdata.totalWhatsappCount,
      }

    case 'DELETE_NPS_SCORE_DETAIL':
      return {
        ...state,
        npsscore_detail: '',
      }

    case 'DELETE_NPS_REPORT':
      return {
        ...state,
        npsreport: '',
      }

    case 'SET_FULL_REPORT_TABLE_PAGEVALUE':
      return {
        ...state,
        page: action.payload.tablepageval,
      }

    case 'SEARCHED_LEARNER_RECORDS':
      return {
        ...state,
        learnersearch: action.payload.learnersearch,
        searchcategory: action.payload.searchcategory,
        searchvalue: action.payload.searchvalue,
      }

    case 'DAILY_ACTIVE_LEARNERS':
      return {
        ...state,
        dailyactivelearners: action.payload.dailyactivelearners,
      }

    case 'COURSE_GRADUATIONS_DATA':
      return {
        ...state,
        coursegraduations: action.payload.coursegraduations,
      }

    case 'DELETE_LEARNER_SEARCH_DATA':
      return {
        ...state,
        learnersearch: '',
        searchcategory: '',
        searchvalue: '',
        dailyactivelearners: '',
        coursegraduations: '',
      }

    default:
      return state
  }
}
