import React from 'react'

const TopBar = () => {
  return (
    <div className="topbar">
      <div>Pegasus Labs Management</div>
    </div>
  )
}

export default TopBar
