import axios from 'axios'
import Cookies from 'universal-cookie'
import { CAN_CRT_CRS } from '../constants/constant.js'

const configs = require('../config/configs.js')

// axios.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     return response
//   },
//   function (error) {
//     // Do something with response error
//     console.log('inside interceptor')
//     return Promise.resolve(error.response)
//   }
// )
axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
var BASE_URL = configs.routes.BASE_URL_PRODUCTION
//var BASE_URL = configs.routes.BASE_URL_DEVELOPMENT;
var BASE_URL_TEST_SERVER = configs.routes.BASE_URL_TEST_SERVER
//var BASE_URL_TEST_SERVER = configs.routes.BASE_URL_DEVELOPMENT_TEST_SERVER;
var TEST_SERVER_KEY = configs.routes.TEST_SERVER_KEY
// var TEST_SERVER_KEY = configs.routes.TEST_SERVER_KEY_TESTING;;
var TEST_SERVER_SECRET = configs.routes.TEST_SERVER_SECRET
const cookies = new Cookies()

var BASE_URL_DEVELOPMENT = configs.routes.BASE_URL_PRODUCTION
//var BASE_URL_DEVELOPMENT = configs.routes.BASE_URL_DEVELOPMENT;

export var getUserPermissions = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_own_permissions?'
    let query = ''
    let aid = cookies.get('aid')
    let isLoggedIn = cookies.get('isLoggedIn')
    let pid = cookies.get('pid')

    if (!isLoggedIn)
      return resolve({
        data: {
          permissionMap: {},
          permissions: { VIEW: 1, CREATE: 2, UPDATE: 3, DELETE: 4 },
          message: 'Login Again',
        },
        success: false,
        status: 400,
      })
    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    return axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUserPermissions data!!', res.data)
          return resolve({ ...res.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log({ err })
        return resolve(err)
      })
  })
}

export var getAllSalesEmails = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_all_sales_levels?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllRoleList data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getAllRoleList = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_all_roles?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllRoleList data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var getCoursesForRole = ({
  applicationsOnly = false,
  brandIds = [],
}) => {
  return new Promise(async (resolve, reject) => {
    console.log({ brandIds })
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_all_courses?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    if (brandIds?.length) query += '&brandIds=' + JSON.stringify(brandIds)
    if (applicationsOnly) query += '&applications_enabled=' + true
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_role_courses data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var fetchBatchesOfCourse = (idArr, brandIds = []) => {
  return new Promise(async (resolve, reject) => {
    console.log({ idArr })
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_course_batches?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'crs_pg_id_arr=' + JSON.stringify(idArr)
    if (brandIds?.length) query += '&brandIds=' + JSON.stringify(brandIds)
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    console.log({ query })
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_course_batches data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getUserDetailsByBatch = (
  selectedRole,
  selectedCourse,
  selectedBatch,
  batchType,
  selectedLocation
) => {
  console.log({
    selectedRole,
    selectedCourse,
    selectedBatch,
    batchType,
    selectedLocation,
  })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_users_permissions_batch?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    let crs_pg_id
    if (selectedCourse.name == 'All Courses') {
      crs_pg_id = '*'
    } else {
      crs_pg_id = selectedCourse._id
    }
    let cid
    if (selectedBatch.btch_name == 'All Batches') {
      cid = '*'
    } else {
      cid = selectedBatch._id
    }
    let loc_id
    if (selectedLocation.name == 'All Locations') {
      loc_id = '*'
    } else {
      loc_id = selectedLocation._id
    }
    query += 'rid=' + selectedRole._id
    query += '&crs_pg_id=' + crs_pg_id
    query += '&cid=' + cid
    query += '&batchType=' + batchType
    query += '&loc_id=' + loc_id
    query += '&aid=' + aid
    query += '&pid=' + pid
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_course_batches data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var getAllPossibleLocations = (crs_pg_idsArr, crs_idsArr) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_all_course_locations?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'crs_pg_id_arr=' + JSON.stringify(crs_pg_idsArr)
    query += '&crs_id_arr=' + JSON.stringify(crs_idsArr)
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_all_course_locations data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var saveNewLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/set_new_course_location?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query+= 'crs_pg_id=' + crs_pg_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, location },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_new_course_location data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var editLocation = (location, loc_id) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/edit_course_location?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, loc_id, location },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('edit_course_location data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var deleteLocation = (location) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/remove_course_location?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query+= 'crs_pg_id=' + crs_pg_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    axios({
      method: 'delete',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, location },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_new_course_location data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var checkUserByEmail = (user) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_user_by_email?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'email=' + user.email
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_user_by_phone data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var assignPermissionToUser = ({
  email,
  role,
  courses,
  batches,
  locations,
  name,
  uid,
  canCreateCourse,
  reporting_manager,
}) => {
  console.log({
    params: { role, courses, batches, locations, name, uid },
  })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/set_user_permissions_v2?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    let can_crt_crs =
      role.rname == CAN_CRT_CRS.COURSE_ADMIN ||
      role.rname == CAN_CRT_CRS.COURSE_ADMIN_B2B
        ? canCreateCourse
        : undefined
    role = JSON.stringify(role)
    courses = JSON.stringify(courses)
    batches = JSON.stringify(batches)
    locations = JSON.stringify(locations)
    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        pid,
        email,
        role,
        courses,
        batches,
        locations,
        uid,
        can_crt_crs,
        reporting_manager,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_user_permissions_v2 data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var updatePermissionToUser = ({
  email,
  role,
  courses,
  batches,
  locations,
  name,
  uid,
  canCreateCourse,
  rid,
  reporting_manager,
}) => {
  console.log({
    params: {
      role,
      courses,
      batches,
      locations,
      name,
      uid,
      canCreateCourse,
      rid,
    },
  })
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/update_user_permissions_v2?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    if (courses[0].name != 'All Courses') {
      let crs_id_arr = courses.map((crs) => crs._id)
      courses = [...crs_id_arr]
    }
    if (batches[0].btch_name != 'All Batches') {
      let batch_id_arr = batches.map((batch) => batch._id)
      batches = [...batch_id_arr]
    }
    if (locations[0].name != 'All Locations') {
      let loc_id_arr = locations.map((loc) => loc._id)
      locations = [...loc_id_arr]
    }
    let can_crt_crs =
      role.rname == CAN_CRT_CRS.COURSE_ADMIN ||
      role.rname == CAN_CRT_CRS.COURSE_ADMIN_B2B
        ? canCreateCourse
        : undefined

    console.log('can_crt_crs', can_crt_crs)

    // role = JSON.stringify(role)
    courses = JSON.stringify(courses)
    batches = JSON.stringify(batches)
    locations = JSON.stringify(locations)
    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        aid,
        pid,
        email,
        rid,
        courses,
        batches,
        locations,
        uid,
        can_crt_crs,
        reporting_manager,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_user_permissions_v2 data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var removeUserRole = (opsUser) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/remove_user_role?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    axios({
      method: 'delete',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { aid, pid, opsUserId: opsUser._id },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('remove_user_role data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var saveCourseLocation = (location, cid) => {
  console.log({ location, cid })
  return new Promise(async (resolve, reject) => {
    if (location) {
      var loc_id = location._id
    }
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/set_course_location?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: { loc_id, cid, aid, pid },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('set_course_location data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var fetchScholarshipMatrix = (crs_pg_id, location_id) => {
  return new Promise(async (resolve, reject) => {
    console.log({ crs_pg_id, location_id })
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/fetch_scholarship_matrix'
    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        location_id,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetch_scholarship_matrix data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var createScholarshipMatrix = (
  crs_pg_id,
  location_id,
  matrix_levels
) => {
  return new Promise(async (resolve, reject) => {
    console.log({ crs_pg_id, location_id, matrix_levels })
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/create_scholarship_level'
    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        crs_pg_id,
        location_id,
        matrix_levels,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('create_scholarship_level data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var updateScholarshipMatrix = (matrix_id, matrix_levels) => {
  return new Promise(async (resolve, reject) => {
    console.log({ matrix_id, matrix_levels })
    let statusCode
    let URL = BASE_URL + '/cfpanelBatch/update_scholarship_level'
    axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        matrix_id,
        matrix_levels,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('update_scholarship_level data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getUserTokens = (aid, pid) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_user_token?'
    let query = ''
    // let aid = cookies.get('aid')
    // let pid = cookies.get('pid')

    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUserTokens data!!', res.data)
          return resolve({ ...res.data.data, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getAllUsers = (req, res) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/opsrolepermission/get_ops_users?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query = ''
    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    axios({
      method: 'get',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllOpsUser data!!', res.data)
          return resolve({ data: [...res.data.data], status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ data: [...res.data], status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
