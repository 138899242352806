import React, {useState, useEffect} from "react"
import { LectureMetrics, NavigationBar, getFacultyData } from "../ScheduleManagementHelper"
import { Input, Label, Button } from "reactstrap"
import { Redirect, NavLink } from 'react-router-dom';
import LoadingOverlay from "../LoadingOverlay";
import Upcoming from "./Upcoming";
import Completed from "./Completed";
import Cancelled from "./Cancelled";

const DashboardMainBody = ({
    page, 
    selectedBatch, 
    selectedCourse, 
    lectures, 
    setLectures, 
    classMap, 
    setClassMap, 
    chapMap, 
    setChapMap, 
    faculty, 
    setFaculty, 
    metricsData, 
    setMetricsData, 
    isLoading, 
    setIsLoading
}) => {
    const [filters, setFilters] = useState({
        from: "",
        to: ""
    })
    const resetFilters = e =>{
        setFilters({
            from: "",
            to: ""
        })
    }
    const changeFilters = e => {
        setFilters({
            ...filters,
            [e.target.name] : e.target.value
        })
    }
    // const [isLoading, setIsLoading] = useState(false)
    // const [classMap, setClassMap] = useState({})
    // const [chapMap, setChapMap] = useState({})
    // const [metricsData, setMetricsData] = useState({})
    // const [lectures, setLectures] = useState(lectures)
    // const [faculty, setFaculty] = useState({})

    useEffect(()=>{
        console.log("Page is changed! UseEffect!")
        if(selectedBatch && selectedCourse){
            setIsLoading(true)
            if(filters.from && filters.to){
                var ad = {
                    from: filters.from, to: filters.to
                }
            }
            getFacultyData({page, ...ad, cid: selectedBatch._id, crsId: selectedCourse._id}).then(res => {
                console.log("Response in getFacultyData :: ", res)
                setIsLoading(false)
                if(res !== false){
                    setLectures(res.liveLecs)
                    setClassMap(res.classMap)
                    setChapMap(res.chapMap)
                    setFaculty(res.faculty)
                    setMetricsData({
                        totPublishedCount: res.totPublishedCount,
                        totCompletedCount: res.totCompletedCount,
                        totCancelledCount: res.totCancelledCount,
                        totPayout: res.totPayout,
                        totTimeCount: {
                            total_done_time: res.totFacultyTime,
                            total_time: res.totTime
                        }
                        // total_time: res.totTime,
                        // total_done_time: res.totalFacultyTime
                    })
                }
            }).catch(err => {
                console.log("Error in getFacultyData :: ", err)
            })
        }

    }, [page, filters.from, filters.to])

    console.log("Faculty Dashboard main render :: ", {filters})
    return <>
        <div className="container" style={{marginTop: "70px"}}>
            <div className="row dateFilters">
                {/* {alert(filters.from+ ', ' + moment(filters.from)+ ', ' + moment(filters.from).format('DD/MM/YYYY'))} */}
                <div className="col-4">
                    <Label>Select Start Data</Label>
                    <Input
                        name="from"
                        type="date"
                        onChange={changeFilters}
                        value={filters.from}
                        />
                </div>
                <div className="col-4">
                    <Label>Select End Data</Label>
                    <Input
                        type="date"
                        name="to"
                        onChange={changeFilters}
                        min={filters.from || null}
                        disabled={!filters.from}
                        value={filters.to}
                    />
                </div>
                <div className="col-2">
                    <Button
                        style={{marginTop: "28px"}}
                        disabled={!filters.from || !filters.to}
                        color="success"
                        onClick={resetFilters}
                    >
                        Reset Filter
                    </Button>
                </div>
            </div>
            <br/>
            <div className='progressBarFacLiveLecture' style={{width: "95%", margin: "auto", background: "lightgrey", borderRadius: "10px"}}>
                <ul>
                    {/* <NavLink to={'/faculty-dashboard'} className={`nav-ul-li ${page === "draft" ? "activeLi" : ""}`}>
                    <li>
                        Draft Lectures
                    </li>
                    </NavLink> */}
                    <NavLink style={{width: "33.33%"}} to={'/faculty-dashboard'} className={`nav-ul-li ${page === "upcoming" ? "activeLi" : ""}`}>
                    <li>
                        Upcoming Lectures
                    </li>
                    </NavLink>
                    <NavLink style={{width: "33.33%"}} to={'/faculty-dashboard/completedLectures'} className={`nav-ul-li ${page === "completed" ? "activeLi" : ""}`}>
                    <li>
                        Completed Lectures
                    </li>
                    </NavLink>
                    <NavLink style={{width: "33.33%"}} to={'/faculty-dashboard/cancelledLectures'} className={`nav-ul-li ${page === "cancelled" ? "activeLi" : ""}`}>
                    <li>
                        Cancelled Lectures
                    </li>
                    </NavLink>
                    </ul>
            </div>
            <div style={{width: "95%", margin: "auto"}}>
                <LoadingOverlay isLoading={isLoading} />
                <LectureMetrics ifFaculty={true} page={page} data={metricsData} />
                <div className={`content ${isLoading ? 'blur' : ''}`}>
                    {caseRendering({page, isLoading, classMap, chapMap, lectures, faculty, selectedBatch, selectedCourse, filters})}
                </div>
            </div>
            <br/>
        </div>
    </>
}

const caseRendering = ({page, classMap, chapMap, lectures, faculty, selectedBatch, selectedCourse, filters}) => {
    if (page === 'upcoming') {
      return <Upcoming page={page} classMap={classMap} lectures={lectures} chapMap={chapMap} faculty={faculty} />;
    } else if (page === 'completed') {
      return <Completed page={page} classMap={classMap} lectures={lectures} chapMap={chapMap} faculty={faculty} selectedCourse={selectedCourse} selectedBatch={selectedBatch} filters={filters} />;
    } else if (page === 'cancelled') {
      return <Cancelled page={page} classMap={classMap} lectures={lectures} chapMap={chapMap} faculty={faculty} />;
    }
    console.log("DIDNT FIND ANYTHING FOR THIS PAGE ::: ", page)
    return <Redirect to="/schedule-management" />;
  };
  
export default DashboardMainBody
