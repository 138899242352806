import axios from 'axios'
import Cookies from 'universal-cookie'
const configs = require('../config/configs.js')

var BASE_URL = configs.routes.BASE_URL_PRODUCTION
const cookies = new Cookies()

// axios.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     return response
//   },
//   function (error) {
//     // Do something with response error
//     console.log('inside interceptor')
//     return Promise.resolve(error.response)
//   }
// )
export var RemoveTableData = () => {
  return {
    type: 'REMOVE_DATA',
  }
}

export var FetchAllBatches = (payload = {}) => {
  console.log(payload)
  return {
    type: 'FETCH_BATCHES',
    payload,
  }
}

export var FetchAllGroups = (payload = {}) => {
  console.log(payload)
  return {
    type: 'FETCH_GROUPS',
    payload,
  }
}

export const fetchFeedReport = () => (dispatch) => {
  let config = {
    headers: {
      'x-access-token': cookies.get('at'),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  console.log('called')
  axios
    .get(BASE_URL + '/feedmonitor/allunans', config)
    .then((report) => {
      console.log(report)
      dispatch({
        type: 'GET_REPORT',
        payload: report.data.data,
      })
    })
    .catch((err) => {
      if (err.response && err.response.status == 403) {
        dispatch({
          type: 'NOT_PERMITTED',
        })
      }
      if (err.response && err.response.status == 498) {
        console.log('Err')
        cookies.remove('at')
        window.location.href = '/head'
      }
    })
}

export const turnOffWarning = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_WARNING',
  })
}

export const turnOffDelWarning = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_DELETE',
  })
}

export const turnOffSuccess = () => (dispatch) => {
  dispatch({
    type: 'REMOVE_SUCCESS',
  })
}

export const saveFeedReport = (input) => (dispatch) => {
  let config = {
    headers: {
      'x-access-token': cookies.get('at'),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  let data = {
    cid: input.cid,
    cname: input.cname,
    gid: input.gid,
  }
  axios
    .post(BASE_URL + '/feedmonitor/unansq', data, config)
    .then((addedRow) => {
      console.log(addedRow)
      dispatch({
        type: 'ADD_ROW',
        payload: addedRow.data.data,
      })
      console.log('heree')
      dispatch(fetchFeedReport())
      dispatch({
        type: 'SUCCESS_MESSAGE',
      })
    })
    .catch((err) => {
      if (err.response && err.response.status == 500) {
        dispatch({
          type: 'NOT_PERMITTED',
        })
      }
      if (err.response && err.response.status == 498) {
        console.log('Err')
        cookies.remove('at')
        window.location.href = '/head'
      }
    })
}

export const fetchallgroup = (id) => (dispatch) => {
  let config = {
    headers: {
      'x-access-token': cookies.get('at'),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  let data = {
    gids: id,
  }
  axios
    .post(BASE_URL + '/feedmonitor/getgrp', data, config)
    .then((groups) => {
      console.log(groups)
      dispatch({
        type: 'FETCH_GROUPS',
        payload: groups.data.data,
      })
    })
    .catch((err) => {
      console.log('Err')
      dispatch({
        type: 'FETCH_GROUPS',
        payload: null,
      })
      if (err.response && err.response.status == 498) {
        console.log('Err')
        cookies.remove('at')
        window.location.href = '/head'
      }
    })
}

export const getallbatches = () => (dispatch) => {
  let config = {
    headers: {
      'x-access-token': cookies.get('at'),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }
  axios
    .get(BASE_URL + '/feedmonitor/getcrs', config)
    .then((course) => {
      console.log(course)
      dispatch(FetchAllBatches(course.data.data))
    })
    .catch((err) => {
      if (err.response && err.response.status == 498) {
        console.log('Err')
        cookies.remove('at')
        window.location.href = '/head'
      }
    })
}

export const delrec = (id) => (dispatch) => {
  let config = {
    headers: {
      'x-access-token': cookies.get('at'),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }

  let data = {
    id: id,
  }

  axios
    .post(BASE_URL + '/feedmonitor/delrepdt', data, config)
    .then((data) => {
      dispatch(fetchFeedReport())
      dispatch({
        type: 'DELETE_MESSAGE',
      })
    })
    .catch((err) => {
      if (err.response && err.response.status == 498) {
        console.log('Err')
        cookies.remove('at')
        window.location.href = '/head'
      }
    })
}
