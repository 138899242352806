const null_state = []

export default (state = {}, action) => {
  switch (action.type) {
    case 'VALID_HOST_ID':
      return { ...state, valid_hosts: action.validHostId }
    case 'FETCH_COURSE_CHAPTER':
      return { ...state, courseChapters: action.courseChapter }
    case 'EDIT_COURSE_CHAPTER':
      return state

    case 'CODING_ASSIGNMENT_DETAILS':
      return {
        ...state,
        assignmentdetails: action.payload.assignmentdetails,
        chpter_id: action.payload.chpter_id,
        assgnment_id: action.payload.assignmentdetails._id,
      }

    case 'SET_CODING_ASSIGNMENT_ID':
      return {
        ...state,
        assgnment_id: action.payload.asgnmt_id,
        chpter_id: action.payload.chapter_id,
      }

    case 'PROGRAMMING_LANGUAGES':
      return {
        ...state,
        languages: action.payload.languages,
      }

    case 'CODING_PROBLEM_DETAILS':
      return {
        ...state,
        codingproblemdetails: action.payload.codingproblem_details,
        editor_language: action.payload.problem_language,
      }

    case 'TEST_CASE_JUDGES':
      return {
        ...state,
        testcasejudges: action.payload.judges,
      }

    case 'CODING_PROBLEM_TESTCASES':
      return {
        ...state,
        testcases: action.payload.testcases,
      }

    case 'CODING_PROBLEM_SUBMISSION_RESULT':
      return {
        ...state,
        problemsubmission: action.payload.problemsubmission,
      }
    case 'FETCH_ALL_COURSE_CHAPTER':
      return {
        ...state,
        allCourseChapters: action.coursechapters,
      }
    case 'FETCH_ALL_COURSE_CONTENT':
      console.log({ action })
      return {
        ...state,
        courseContent: action.courseContent,
      }
    case 'FETCH_LEVEL_ELEC_CHAPER_MAP':
      console.log({ action })
      return {
        ...state,
        levelElecChapterMap: action.levelElecChapterMap,
      }
    case 'FETCH_ALL_COURSE_ELECTIVE_LEVELS':
      console.log({ action })
      return {
        ...state,
        courseElectiveLevel: action.elective,
      }

    case 'SET_NULL_STATE':
      return {
        ...state,
        assignmentdetails: '',
        chpter_id: '',
        assgnment_id: '',
        problemsubmission: '',
        testcases: '',
        codingproblemdetails: '',
        languages: '',
        editor_language: '',
        allCourseChapters: [],
        courseContent: {},
        levelElecChapterMap: {},
        courseElective: [],
      }

    default:
      return state
  }
}
