export const COURSE_CHANGE = 'COURSE_CHANGE'
export const STATUS_CHANGE = 'STATUS_CHANGE'
export const ASSIGNMENT_CHANGE = 'ASSIGNMENT_CHANGE'
export const SAVE_STUDENT_DETAIL = 'SAVE_STUDENT_DETAIL'
export const SAVE_MSG = 'SAVE_MSG'
export const SHOW_ERROR_MSG = 'SHOW_ERROR_MSG'
export const COURSE_PAGE_CHANGE = 'COURSE_PAGE_CHANGE'
export const LOCATION_CHANGE = 'LOCATION_CHANGE'
export const PAGE_CHANGE = 'PAGE_CHANGE'
export const ROWS_PER_PAGE_CHANGE = 'ROWS_PER_PAGE_CHANGE'
