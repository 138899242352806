import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import Cookies from 'universal-cookie'
const configs = require('../../../../config/configs.js')

const AddExtraLabsCard = ({
  id,
  setAddLabsBlock,
  isLoading,
  setIsLoading,
  start_time,
  machinesAllowed,
}) => {
  const [num, setNum] = useState('')

  const cookies = new Cookies()
  useEffect(() => {
    if (!id) {
      alert('No lab id found please contact labs team')
      setAddLabsBlock('false')
    }
  }, [id, setAddLabsBlock, start_time])

  const submitHanlder = (e) => {
    e.preventDefault()
    try {
      const addExtraLabsRequest = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          id: id,
          num: num,
        }

        setIsLoading(true)
        const res = await Axios.post(
          `${baseURL}/cfpanellabs/addExtraLabs`,
          data,
          config
        )

        if (res) {
          if (res.status !== 200) {
            alert(res.data.message)
          }
        }
        setIsLoading(false)
        setAddLabsBlock(false)
      }
      addExtraLabsRequest()
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <div className="labs-add-student-card-main">
      <div className="labs-add-student-card-content">
        <div className="labs-add-student-card-header">
          <span>Add Labs</span>

          <Image
            src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
            onClick={() => setAddLabsBlock(false)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <hr />
        <form onSubmit={(e) => submitHanlder(e)}>
          <Row>
            <Col md={12}>
              <div className="labs-create-new-form">
                <div>
                  Enter Number of labs to be added (Only {machinesAllowed} more
                  can be created)
                </div>
                <input
                  type="number"
                  min={0}
                  max={{ machinesAllowed }}
                  name="num"
                  onChange={(e) => {
                    setNum(e.target.value)
                  }}
                  className="labs-create-new-form-text_input"
                  required={true}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="labs-create-new-form">
                <input
                  type="text"
                  name="id"
                  value={id}
                  className="labs-create-new-form-text_input"
                  required={true}
                  hidden={true}
                  disabled
                />
              </div>
            </Col>
            <Col md={12}>
              <Col md={6}>
                <button
                  className="labs-table-btn-1"
                  type="submit"
                  disabled={isLoading}
                >
                  Add Labs{' '}
                </button>
              </Col>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default AddExtraLabsCard
