import React from 'react'
import { history } from '../../..'

const Sidebar = ({ active }) => {
  return (
    <div className="labs-sidebar">
      <div
        className={
          active === 1
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/labs-management')
        }}
      >
        Labs Mangement
      </div>

      <div
        className={
          active === 2
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/labs-management/images')
        }}
      >
        Images Management
      </div>

      <div
        className={
          active === 3
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/labs-management/cost')
        }}
      >
        Cost Management
      </div>

      <div
        className={
          active === 4
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/labs-management/session')
        }}
      >
        Session Management
      </div>
    </div>
  )
}

export default Sidebar
