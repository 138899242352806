import Cookies from 'universal-cookie'
import {
  CODING_ASSIGNMENT_TYPE,
  AUTHENTICATION_COOKIES,
  PATH,
  DOMAIN,
} from '../constants/constant'
import Axios from 'axios'

const configs = require('../config/configs.js')

const cookies = new Cookies()

var BASE_URL_DEVELOPMENT = configs.routes.BASE_URL_PRODUCTION
//var BASE_URL_DEVELOPMENT = configs.routes.BASE_URL_DEVELOPMENT;

export var startFetchingData = () => {
  return {
    type: 'START_FETCH_DATA',
  }
}

export var errorFetchingData = () => {
  return {
    type: 'ERROR_FETCH_DATA',
  }
}

export var successFetchedData = () => {
  return {
    type: 'SUCCESS_FETCH_DATA',
    //loginData
  }
}

export var startLoginData = (loginData = {}) => {
  return (dispatch) => {
    dispatch(startFetchingData())
    return Axios({
      method: 'POST',
      url: BASE_URL_DEVELOPMENT + '/account/login_w',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        c_code: '91',
        p_no: loginData.phoneNumber,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          console.log(data)
          // localStorage.setItem('aid', data.data.Account._id);
          cookies.set('aid', data.data.Account._id)

          dispatch(successFetchedData())
          return Promise.resolve()
        } else {
          dispatch(errorFetchingData())
          return Promise.reject()
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(errorFetchingData())
        return Promise.reject()
      })
  }
}

export var startVerifyRequest = (accountDetail = {}) => {
  return (dispatch) => {
    dispatch(startFetchingData())
    return Axios({
      method: 'POST',
      url: BASE_URL_DEVELOPMENT + '/account/verify_otp_v16',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        // aid: localStorage.getItem('aid'),
        aid: cookies.get('aid'),
        otp: accountDetail.otp,
        d_id: window.fingerprint.md5hash,
        cl: 'W',
        clv: '1.0',
        vrsn: '1.0',
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // localStorage.setItem('at', data.data.at);
          cookies.set('at', data.data.at, {
            path: PATH,
            domain: DOMAIN,
          })

          // localStorage.setItem('secret', data.data.secret);
          cookies.set('secret', data.data.secret)

          // localStorage.setItem('rt', data.data.rt);
          cookies.set('rt', data.data.rt)

          // localStorage.setItem('pid', data.data.Profile[0]._id)
          cookies.set('pid', data.data.Profile[0]._id)
          cookies.set('isLoggedIn', true)

          dispatch(successFetchedData(data))
          return Promise.resolve()
        } else {
          dispatch(errorFetchingData())
          return Promise.reject()
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(errorFetchingData())
        return Promise.reject()
      })
  }
}

export var startResendOtpRequest = (accountDetail = {}) => {
  return (dispatch) => {
    dispatch(startFetchingData())
    return Axios({
      method: 'POST',
      url: BASE_URL_DEVELOPMENT + '/account/resend_otp',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        // aid: localStorage.getItem('aid'),
        aid: cookies.get('aid'),
        tokn: accountDetail.tokn,
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // localStorage.setItem('aid', data.data.Account._id);
          cookies.set('aid', data.data.Account._id)

          dispatch(successFetchedData())
          return Promise.resolve()
        } else {
          dispatch(errorFetchingData())
          return Promise.reject()
        }
      })
      .catch((e) => {
        console.log(e)
        dispatch(errorFetchingData())
        return Promise.reject()
      })
  }
}

export var startNewAccessTokenRequest = () => {
  return (dispatch) => {
    return Axios({
      method: 'POST',
      url: BASE_URL_DEVELOPMENT + '/account/g_at',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: {
        // aid: localStorage.getItem('aid'),
        a_id: cookies.get('aid'),
        d_id: window.fingerprint.md5hash,
        cl: 'W',
        // rt: localStorage.getItem('rt')
        rt: cookies.get('rt'),
      },
    })
      .then((res) => res.data)
      .then((data) => {
        if (data.success) {
          // localStorage.setItem('at', data.data.at);
          cookies.set('at', data.data.at, {
            path: PATH,
            domain: DOMAIN,
          })

          return Promise.resolve(data)
        } else {
          return Promise.resolve(data)
        }
      })
      .catch((e) => {
        console.log(e)
        return Promise.resolve(e)
      })
  }
}
