import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import authReducer from '../reducers/apiReducers'
import courseReducer from '../reducers/courseReducer'
import certificatePanelReducer from '../reducers/certificatePanelReducer'
import trackingMoreReducer from '../reducers/trackingMoreReducer'
import certificatechangeReducer from '../reducers/certcngReducers'
import panelPermissionReducer from '../reducers/panelPermissionReducer'
import thunk from 'redux-thunk'
import reducer from '../reducers/index'
import learnerMonitoringPanelReducer from '../reducers/learnerMonitoringPanelReducer'
import feedMonitoringPanelReducer from '../reducers/feedMonitoringPanelReducer'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default () => {
  var store = createStore(
    combineReducers({
      auth: authReducer,
      course: courseReducer,
      cfPanel: certificatePanelReducer,
      tm: trackingMoreReducer,
      reducer: reducer,
      cer_chng: certificatechangeReducer,
      lmpanel: learnerMonitoringPanelReducer,
      fmpanel: feedMonitoringPanelReducer,
      panelPermissionReducer: panelPermissionReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  )

  return store
}
