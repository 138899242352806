const null_state = []

export default (state = {}, action) => {
  console.log({ action })
  switch (action.type) {
    case 'PANEL_PERMISSIONS':
      return {
        ...state,
        panelPermissions: action.payload.permissions,
        panelResources: action.payload.resources,
      }
    default:
      return state
  }
}
