import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { Col, Row } from 'react-bootstrap'
import '../../LabsManagement.css'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { history } from '../../../..'
import { Loader } from 'semantic-ui-react'
import moment from 'moment-timezone'
const configs = require('../../../../config/configs.js')

const EditLabsCost = ({ match }) => {
  const costID = match.url.split('/')[3]
  const [isLoading, setIsLoading] = useState(false)
  const [currentISOTime, setCurrentISOTime] = useState('')
  const [formdata, setFormdata] = useState({
    cloud_provider: '',
    region: '',
    ram: '',
    price_per_hour: '',
    valid_from: '',
    valid_till: '',
  })

  const timezone = 'Asia/Calcutta'

  const submitHanlder = (e) => {
    const cookies = new Cookies()
    e.preventDefault()

    try {
      setIsLoading(true)
      const createLabsRequest = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          ...formdata,
          valid_from: moment.tz(formdata.valid_from, timezone).valueOf(),
          valid_till: moment.tz(formdata.valid_till, timezone).valueOf(),
          id: costID,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/editCostById`,
          data,
          config
        )

        if (res) {
          if (res.status === 200) {
            history.push('/labs-management/cost')
          } else {
            alert(res.data.message)
          }
        }
      }
      createLabsRequest()
      setIsLoading(false)
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          id: costID,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getCostByID`,
          data,
          config
        )

        if (res.data.data) {
          const res1 = res.data.data
          setFormdata(res1.costing)
        }

        const currentDate = new Date()
        const isoTime = currentDate.toISOString()
        const date = isoTime.slice(0, 10)
        const time = isoTime.slice(11, 16)
        setCurrentISOTime(`${date}T${time}`)
        setIsLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    fetchData()
  }, [costID])

  const upadteFormdata = (e) => {
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={3} />
      <div className="labs-main" style={{ paddingBottom: '10vh' }}>
        <TopBar />
        <div className="labs-content">
          <div>
            <div className="labs-create-new-header">Edit Cost</div>
            <div>
              <b>NOTE: </b> &nbsp; Please do not edit cost without teach-team's
              permission.
            </div>
            <hr />
            <form onSubmit={(e) => submitHanlder(e)}>
              <Row>
                <Col md={6}>
                  <div className="labs-create-new-form">
                    <div>Price Per Hour (In USD only)</div>
                    <input
                      className="labs-create-new-form-text_input"
                      required={true}
                      type="number"
                      value={formdata.price_per_hour}
                      name="price_per_hour"
                      onChange={upadteFormdata}
                      step="any"
                    ></input>
                  </div>
                </Col>

                <Col md={6}>
                  <div className="labs-create-new-form">
                    <div>Select Price Validity Ending</div>
                    <input
                      type="datetime-local"
                      className="labs-create-new-form-text_input"
                      name="valid_till"
                      value={formdata.valid_till}
                      required={true}
                      onChange={upadteFormdata}
                      min={currentISOTime}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <Col md={2}>
                    <button className="labs-table-btn-1" type="submit">
                      Edit Cost
                    </button>
                  </Col>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditLabsCost
