const initialState = {
  t_data_invalid: false,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case 'LOGGED_IN_TRUE':
      return {
        ...state,
      }
    case 'TRACKING_DATA_INVALID':
      return { ...state, t_data_invalid: true }
    default:
      return state
  }
}
