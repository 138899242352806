import React from 'react'
import ViewSDKClient from '../../viewSDKClient'
import { useEffect } from 'react'

const RenderMenu = ({ url, height = '100vh' }) => {
  useEffect(() => {
    loadPDF()
  }, [url])
  const loadPDF = () => {
    const viewSDKClient = new ViewSDKClient()
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        'pdf-div',
        {
          embedMode: 'SIZED_CONTAINER',
          showAnnotationTools: false,
          showLeftHandPanel: false,
          showPageControls: false,
          showDownloadPDF: false,
          showZoomControl: false,
          showPrintPDF: false,
          enableFormFilling: false,
          exitPDFViewerType: 'CLOSE',
          showThumbnails: false,
          showBookmarks: false,
          showFullScreen: true,
        },
        url
      )
    })
  }
  return (
    <div className="mt-28">
      <div
        style={{ height: height }}
        id="pdf-div"
        className="full-window-div border border-gray-100 h-screen"
      ></div>
    </div>
  )
}
export default RenderMenu
