import React, { useEffect, useState } from 'react'
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { Col, Row } from 'reactstrap'
import {
  LABS_REQUEST_STATUS,
  LABS_SERVER,
  LABS_STATUS,
} from '../../../../constants/constant'
import AddStudentCard from './AddStudentCard'
import CsvCard from './CsvCard'
import { Loader } from 'semantic-ui-react'
import EditLabsCard from './EditLabsCard'
import moment from 'moment'
import LogsCard from './LogsCard'
import AddExtraLabsCard from './AddExtraLabsCard'
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material'
import { history } from '../../../..'
const configs = require('../../../../config/configs.js')

const SingleLabsPage = ({ match }) => {
  const labsReqID = match.url.split('/')[2]
  const [labsRequest, setLabsRequest] = useState('')
  const [timezone, setTimezone] = useState('')
  const [labs, setLabs] = useState(null)
  const [block, setBlock] = useState(false)
  const [id, setID] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [csvBlock, setCsvBlock] = useState(false)
  const [editLabBlock, setEditLabBlock] = useState(false)
  const [addLabsBlock, setAddLabsBlock] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showLogs, setShowLogs] = useState()
  const [machineId, setMachineID] = useState('')
  const [logs, setLogs] = useState([])
  let num = 0

  const unassign = async (id) => {
    const cookies = new Cookies()
    setIsLoading(true)
    try {
      const baseURL = configs.routes.BASE_URL_PRODUCTION
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
        },
      }

      const data = {
        id: id,
      }

      await Axios.post(`${baseURL}/cfpanellabs/unassign`, data, config)
    } catch (error) {
      console.error(error.message)
    }
    setRefresh(!refresh)
    setIsLoading(false)
  }

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const fetchConstants = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          id: labsReqID,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getSingleLab`,
          data,
          config
        )

        if (res.data.code !== 420) {
          const details = res.data.data
          setLabsRequest(details.labsRequests)
          setTimezone(details.labsRequests.timezone)
          setLabs(details.machines)
        } else {
          alert(res.data.message)
          history.push('/labs-management')
        }
      } catch (error) {
        console.error(error.message)
      }
      setIsLoading(false)
    }
    fetchConstants()
  }, [block, refresh, csvBlock, editLabBlock, labsReqID, addLabsBlock])

  const minToTime = (time) => {
    const result =
      Math.floor(time / 60) +
      ':' +
      (time - Math.floor(time / 60) * 60 < 10
        ? '0' + (time - Math.floor(time / 60) * 60)
        : time - Math.floor(time / 60) * 60)

    return result
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }
  return (
    <>
      <Sidebar active={1} />
      {block && !isLoading && (
        <AddStudentCard
          id={id}
          setBlock={setBlock}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {csvBlock && !isLoading && (
        <CsvCard
          id={labsReqID}
          setCsvBlock={setCsvBlock}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      )}
      {showLogs && !isLoading && (
        <LogsCard id={machineId} setShowLogs={setShowLogs} logs={logs} />
      )}
      {editLabBlock && !isLoading && labsRequest.start_time && (
        <EditLabsCard
          id={labsReqID}
          setEditLabBlock={setEditLabBlock}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          start_time={labsRequest.start_time}
        />
      )}

      {addLabsBlock && !isLoading && (
        <AddExtraLabsCard
          id={labsReqID}
          setAddLabsBlock={setAddLabsBlock}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          machinesAllowed={labsRequest.maxMachine - labsRequest.no_of_machine}
        />
      )}
      <div className="labs-main" style={{ paddingBottom: '10vh' }}>
        <TopBar />
        <div className="labs-content">
          <div>
            <Row>
              <Col md={4}>
                <div className="labs-create-new-header">
                  {labsRequest.lab_name && labsRequest.lab_name}
                </div>
              </Col>

              <Col md={2}>
                {labsRequest.status === 1 &&
                  labsRequest.maxMachine > labsRequest.no_of_machine && (
                    <button
                      onClick={() =>
                        addLabsBlock
                          ? setAddLabsBlock(false)
                          : setAddLabsBlock(true)
                      }
                      className="labs-table-btn-2"
                    >
                      Add Lab
                    </button>
                  )}
              </Col>

              <Col md={2}>
                {labsRequest.status === 1 && (
                  <button
                    onClick={() =>
                      editLabBlock
                        ? setEditLabBlock(false)
                        : setEditLabBlock(true)
                    }
                    className="labs-table-btn-2"
                  >
                    Extend Time
                  </button>
                )}
              </Col>

              <Col md={2}>
                {labsRequest.status === 1 && (
                  <button
                    onClick={() =>
                      csvBlock ? setCsvBlock(false) : setCsvBlock(true)
                    }
                    className="labs-table-btn-2"
                  >
                    Assign Labs
                  </button>
                )}
              </Col>
              <Col md={2}>
                <button
                  onClick={() =>
                    refresh ? setRefresh(false) : setRefresh(true)
                  }
                  className="labs-table-btn-1"
                >
                  Refresh
                </button>
              </Col>
            </Row>

            <hr />

            <Row style={{ marginTop: '1rem' }}>
              <Col md={12} style={{ marginBottom: '1rem' }}>
                <h5>Lab Properties:</h5>
              </Col>
              {labsRequest && (
                <>
                  <Col md={4}>
                    <ul>
                      <li>
                        <b> Start Time: </b>
                        {minToTime(labsRequest.activeperiod_start_time)}
                      </li>
                      <li>
                        <b> End Time: </b>
                        {minToTime(labsRequest.activeperiod_end_time)}
                      </li>
                      <li>
                        <b>Creation Time: </b>
                        {moment
                          .utc(labsRequest.start_time)
                          .tz(labsRequest.timezone)
                          .format('YYYY-MM-DD, HH:mm:ss')}
                      </li>
                      <li>
                        <b>Deletion Time: </b>
                        {moment
                          .utc(labsRequest.end_time)
                          .tz(labsRequest.timezone)
                          .format('YYYY-MM-DD, HH:mm:ss')}
                      </li>

                      <li>
                        <b>Server: </b>

                        {LABS_SERVER[labsRequest.cloud_provider]}
                      </li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul>
                      <li>
                        <b>Region: </b>
                        {labsRequest.region}
                      </li>
                      <li>
                        <b>Ram: </b>
                        {labsRequest.ram}
                      </li>
                      <li>
                        <b>Disk Size: </b>
                        {labsRequest.disk_size && labsRequest.disk_size + 'gb'}
                      </li>
                      <li>
                        <b>vCPU: </b>
                        {labsRequest.vcpu && labsRequest.vcpu + 'vcpu'}
                      </li>
                      <li>
                        <b>Image: </b>
                        {labsRequest.imageName}
                      </li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul>
                      <li>
                        <b>No of machines: </b>

                        {labsRequest.no_of_machine}
                      </li>
                      <li>
                        <b>Time Zone: </b>

                        {labsRequest.timezone}
                      </li>
                      <li>
                        <b>TimeOut: </b>
                        {labsRequest.time_out} mins
                      </li>
                      <li>
                        <b>Status: </b>

                        {LABS_REQUEST_STATUS[labsRequest.status]}
                      </li>
                    </ul>
                  </Col>
                  <Col md={12}>
                    {' '}
                    <li>
                      <b>Labs Cost: </b>
                      <b>${labsRequest.cost} </b>(Est cost of computational
                      power) + <b>${labsRequest.storageCost}</b> (Est cost of
                      storage power) ={' '}
                      <b>
                        $
                        {(
                          Number(labsRequest.cost) +
                          Number(labsRequest.storageCost)
                        ).toFixed(4)}
                      </b>
                    </li>
                  </Col>
                </>
              )}
            </Row>

            <hr />

            <Row style={{ marginTop: '1rem' }}>
              <TableContainer component={Paper}>
                <Table striped bordered hover>
                  <TableHead
                    className="labs-table-req-head"
                    style={{ textAlign: 'center' }}
                  >
                    <TableCell style={{ textAlign: 'center' }}>No.</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>IP</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Status
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {' '}
                      Start Time
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {' '}
                      End Time
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Creation Time
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Deletion Time
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>Logs</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      Student Assigned
                    </TableCell>
                    <TableCell></TableCell>
                  </TableHead>
                  <tbody>
                    {labs
                      ? labs.map((elem, i) => {
                          return (
                            <tr key={i} style={{ textAlign: 'center' }}>
                              <td>{++num}</td>
                              <td>{elem.public_ip}</td>
                              <td>{LABS_STATUS[elem.status]}</td>
                              <td>{minToTime(elem.activeperiod_start_time)}</td>
                              <td>{minToTime(elem.activeperiod_end_time)}</td>
                              <td>
                                {moment
                                  .utc(elem.start_time)
                                  .tz(timezone)
                                  .format('YYYY-MM-DD, HH:mm:ss')}
                              </td>
                              <td>
                                {moment
                                  .utc(elem.end_time)
                                  .tz(timezone)
                                  .format('YYYY-MM-DD, HH:mm:ss')}
                              </td>

                              <td>
                                <button
                                  className="labs-table-btn-1 labs-edit-btn"
                                  onClick={() => {
                                    setMachineID(elem._id)
                                    setLogs(elem.logs)
                                    setShowLogs(true)
                                  }}
                                >
                                  Logs
                                </button>
                              </td>
                              <td>
                                {elem.student_aid ? (
                                  <>
                                    <>{elem.student_email || ''}</>
                                  </>
                                ) : (
                                  <>
                                    {labsRequest.status === 1 ? (
                                      <button
                                        className="labs-table-btn-1"
                                        onClick={() => {
                                          setID(elem._id)
                                          setBlock(true)
                                        }}
                                      >
                                        Assign Lab
                                      </button>
                                    ) : (
                                      'N/A'
                                    )}
                                  </>
                                )}
                              </td>
                              <td>
                                {elem.student_aid &&
                                labsRequest.status === 1 ? (
                                  <button
                                    style={{ marginLeft: '1rem' }}
                                    className="labs-table-btn-1 labs-edit-btn labs-Inactive"
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          'Do you want to unassign this labs?'
                                        )
                                      ) {
                                        unassign(elem._id)
                                      }
                                    }}
                                  >
                                    Unassign
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          )
                        })
                      : null}
                  </tbody>
                </Table>
              </TableContainer>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleLabsPage
