import React, { useEffect, useState } from 'react'
import '../../LabsManagement.css'
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment-timezone'
import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material'
import { Loader } from 'semantic-ui-react'
import { LABS_SERVER } from '../../../../constants/constant'
const configs = require('../../../../config/configs.js')

const LabsCost = () => {
  const [requests, setRequests] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)

  const timezone = 'Asia/Calcutta'

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getRequests = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          page,
          rowsPerPage,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/listLabsCost`,
          data,
          config
        )

        if (res.data.data) {
          setRequests(res.data.data.labsPricing)
          setTotalCount(parseInt(res.data.data.totalLabsPricing))
        }
        setIsLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    getRequests()
  }, [refresh, page, rowsPerPage])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={3} />
      <div className="labs-main">
        <TopBar />
        <div className="labs-content">
          <Row>
            <Col md={8} className="labs-table-header">
              Labs Cost Management
            </Col>

            <Col md={2}>
              <button
                onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                className="labs-table-btn-2"
              >
                Refresh
              </button>
            </Col>
            <Col md={2}>
              <Link to="/labs-management/addCost">
                <button className="labs-table-btn-1">+ Create</button>
              </Link>
            </Col>
          </Row>
          <p>*Price is per/hour for instance and GB/month for volumes</p>

          <Row style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="labs-table-req-head">
                  <TableCell>Cloud Provider</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Ram</TableCell>
                  <TableCell>VCPU</TableCell>
                  <TableCell>Instance Type</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Valid From</TableCell>
                  <TableCell>Valid Till</TableCell>
                  <TableCell>Options</TableCell>
                </TableHead>
                <tbody>
                  {requests
                    ? requests.map((elem, i) => {
                        return (
                          <tr key={i}>
                            <td>{LABS_SERVER[elem.cloud_provider]}</td>
                            <td>{elem.region}</td>
                            <td>{elem.ram}</td>
                            <td>{elem.vcpu && elem.vcpu + '-vpcu'}</td>
                            <td>{elem.instance_type}</td>
                            <td>$ {elem.price_per_hour}</td>
                            <td>
                              {' '}
                              {moment
                                .utc(elem.valid_from)
                                .tz(timezone)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                            <td>
                              {' '}
                              {moment
                                .utc(elem.valid_till)
                                .tz(timezone)
                                .format('YYYY-MM-DD HH:mm:ss')}
                            </td>
                            <td>
                              <Link
                                to={`/labs-management/editLabsCost/${elem._id}`}
                              >
                                <button className="labs-table-btn-1 labs-edit-btn">
                                  Edit
                                </button>
                              </Link>
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </Table>
            </TableContainer>

            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LabsCost
