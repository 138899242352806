import React from 'react'
import ReactDOM from 'react-dom/client'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import createHistory from 'history/createBrowserHistory'

import Dashboard from './layout/Dashboard/Dashboard'
import configStore from './store/storeConfig'
import { renewAccessToken, logoutUser } from './actions/courseAction'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'fomantic-ui-css/semantic.css';
import 'react-datepicker/dist/react-datepicker.css'
import './lib/fingerprint.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStroopwafel, faStar } from '@fortawesome/free-solid-svg-icons'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { yellow, amber, green } from '@mui/material/colors'
import {
  CODING_ASSIGNMENT_TYPE,
  AUTHENTICATION_COOKIES,
  PATH,
  DOMAIN,
} from './constants/constant'
const cookies = new Cookies()
const configs = require('./config/configs.js')

var BASE_URL = configs.routes.BASE_URL_PRODUCTION
//var BASE_URL = configs.routes.BASE_URL_DEVELOPMENT;

library.add(faStroopwafel, faStar)

const theme = createTheme({
  palette: {
    secondary: {
      main: yellow[200],
    },
    success: {
      // main: '#87ff7b',
      main: green['A200'],
    },
    error: {
      main: '#E66657',
    },
    warning: {
      main: amber[400],
    },
  },
  components: {
    MuiButtonBase: {
      variants: [
        {
          props: { color: 'secondary' },
          style: {
            backgroundColor: yellow[200],
          },
        },
      ],
    },
  },
})
// primary: {
//   plainColor: 'valid CSS color',
//   plainHoverBg: 'valid CSS color',
//   plainActiveBg: 'valid CSS color',
//   // ...other variant tokens
// },
// neutral: {
//   plainColor: 'valid CSS color',
//   plainHoverBg: 'valid CSS color',
//   plainActiveBg: 'valid CSS color',
//   // ...other variant tokens
// },
// danger: {
//   plainColor: 'valid CSS color',
//   plainHoverBg: 'valid CSS color',
//   plainActiveBg: 'valid CSS color',
//   // ...other variant tokens
// },
// info: {
//   plainColor: 'valid CSS color',
//   plainHoverBg: 'valid CSS color',
//   plainActiveBg: 'valid CSS color',
//   // ...other variant tokens
// },
// success: {
//   plainColor: 'valid CSS color',
//   plainHoverBg: 'valid CSS color',
//   plainActiveBg: 'valid CSS color',
//   // ...other variant tokens
// },
// warning: {
//   plainColor: 'valid CSS color',
//   plainHoverBg: 'valid CSS color',
//   plainActiveBg: 'valid CSS color',
//   // ...other variant tokens
// },
let expireOlderCookies = (name, paths) => {
  for (var i = 0, l = paths.length; i < l; i++) {
    cookies.remove(name, paths[i])
  }
}
let updateURLParameter = (url, param, paramVal) => {
  var newAdditionalURL = ''
  var tempArray = url.split('?')
  var baseURL = tempArray[0]
  var additionalURL = tempArray[1]
  var temp = ''
  if (additionalURL) {
    tempArray = additionalURL.split('&')
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL += temp + tempArray[i]
        temp = '&'
      }
    }
  }

  var tokn = temp + '' + param + '=' + paramVal
  return baseURL + '?' + newAdditionalURL + tokn
}
let removeAuthCookies = () => {
  console.log('\n:::::::: Removing Auth Cookies :::::::: ')
  for (let i = 0; i < AUTHENTICATION_COOKIES.length; i++) {
    cookies.remove(AUTHENTICATION_COOKIES[i], {
      path: PATH,
      domain: DOMAIN,
    })
    console.log(
      '\t Removed - Cookie : ',
      AUTHENTICATION_COOKIES[i],
      ', Domain : ',
      DOMAIN,
      ', Path : ',
      PATH
    )
  }
  console.log(':::::::: Removed Auth Cookies ::::::::\n')
}

Axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('---------- Error ::: ', error, ' --------------')
    console.log({ errorResConfig: error.response.config })

    const originalRequest = error.config
    console.log({ error }, { originalRequest })
    if (error.response.status === 498 && !originalRequest._retry) {
      originalRequest._retry = true // to avoid loops

      if (!error.response.config.url.includes('/g_at_w')) {
        console.log(
          ' ================= Calling /g_at_w for token renewal ============== '
        )
        try {
          // expireOlderCookies('at', ['/', '/dashboard'])
          let at_updated_tkn = await renewAccessToken()

          console.log(
            ' --- Response from /g_at_w ::: ',
            { newAtToUse: at_updated_tkn },
            ' ---- '
          )

          if (!at_updated_tkn) {
            logoutUser() // will be called when the at token is not refreshed.
            return Promise.reject(error)
          }

          // three ways to use the new at token,
          // first, in req.body.tokn (for post calls)
          // second, in req.query.tokn (for get calls)
          // third, in req.headers.x-access-token

          if (
            error.response.config.method === 'POST' ||
            error.response.config.method === 'post'
          ) {
            let dataToAdd = JSON.parse(error.response.config.data)
            error.response.config.data = {
              ...dataToAdd,
              tokn: at_updated_tkn,
            }
          } else {
            let newURlwithQueryParams = updateURLParameter(
              error.response.config.url,
              'tokn',
              at_updated_tkn
            )
            console.log({ newURlwithQueryParams })
            error.response.config.url = newURlwithQueryParams
          }

          error.response.config.headers['x-access-token'] = at_updated_tkn
          error.response.headers['x-access-token'] = at_updated_tkn

          console.log(
            '\n ---- Final API call ::: ',
            error.response.config,
            ' ---- \n'
          )

          // call the api which initially gave 498.
          return await Axios(error.response.config)
        } catch (err) {
          logoutUser() // will be called if there is an error in /g_at_w
          return Promise.resolve(error.response || error)
          // return Promise.reject(error)
        }
      } else {
        logoutUser() // will be called if there is 498 issue but the route is NOT - /courselib and /g_at_w
        // return Promise.reject(error)
        return Promise.resolve(error.response || error)
      }
    } else {
      // originalRequest._retry = true // to avoid loops
      // Do something with response error
      console.log('inside interceptor')
      return Promise.resolve(error.response || error)
    }
  }
)
export const history = createHistory()

var store = configStore()

const root = ReactDOM.createRoot(document.getElementById('root'))
var jsx = (
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <Dashboard />
      </ThemeProvider>
    </Router>
  </Provider>
)
root.render(jsx)
