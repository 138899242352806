import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import styles from './ProcessSettings.scss'
import formStyles from './form.scss'
import { Loader } from 'semantic-ui-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { Button, ButtonGroup, Col, Row, Tooltip } from 'reactstrap'
import Form from '@rjsf/core'
import validator from '@rjsf/validator-ajv8'
import { FormBuilder } from './FormBuilder'
import { getAdmissionSchema, getUID } from '../../actions/courseAction'
import { EXAM_DASHBOARD_URL } from '../../constants/constant'
import './PlacementPortal.scss'
import Sidebar from './Components/Sidebar'
// import AddTest from '../AddTest/AddTest'
// import TestInstructions from '../TestInstructions/TestInstructions'
// import AddProductLink from '../AddProductLink/AddProductLink'

const configs = require('../../config/configs')

var BASE_URL = configs.routes.BASE_URL_PRODUCTION
const cookies = new Cookies()

function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const PlacementSettings = (props) => {
  let validateAdmissionSchemaBlock = () => {
    return (
      schemaName &&
      schemaName.length > 10 &&
      slug &&
      slug.length > 10 &&
      dataSchema &&
      isJsonString(dataSchema) &&
      schemaPic &&
      uiSchema &&
      isJsonString(uiSchema)
    )
  }

  let isValidSchema = (schema) => {
    try {
      JSON.parse(schema)
      return true
    } catch (err) {
      console.log({ err })
      return false
    }
  }

  const [main, setMain] = useState(true)
  const [blockModalVis, setBlockModalVis] = useState(false)
  const [schemaName, setSchemaName] = useState()
  const [schemaPic, setSchemaPic] = useState()
  const [slug, setSlug] = useState()
  const [dataSchema, setDataSchema] = useState()
  const [tempDataSchema, setTempDataSchema] = useState()
  const [uiSchema, setUISchema] = useState()
  const [tempUiSchema, setTempUiSchema] = useState()
  const [basicBlocksUsed, setBasicBlocksUsed] = useState([])
  const [educationalBlocksUsed, setEducationalBlocksUsed] = useState([])
  const [professionalBlocksUsed, setProfessionalBlocksUsed] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [blocks, setBlocks] = useState([])
  const [basicBlocks, setBasicBlocks] = useState([])
  const [educationalBlocks, setEducationalBlocks] = useState([])
  const [professionalBlocks, setProfessionalBlocks] = useState([])
  const [course, setCourse] = useState()
  const [courseGid, setCourseGid] = useState()
  const [appSchemas, setAppSchemas] = useState([])
  const [usedCourseIds, setUsedCourseIds] = useState({})
  const [hideRenderedBlock, setHideRenderedBlock] = useState(false)
  const [optionsBlockState, setOptionsBlockState] = useState(0)
  const courses = props.courses || []
  const [isEdit, setIsEdit] = useState(false)
  const [showTestModal, setShowTestModal] = useState(false)
  const [activeBlockId, setActiveBlockId] = useState()
  const [appBlockSchemData, setAppBlockSchemData] = useState([])
  const [addedQuizzes, setAddedQuizzes] = useState()
  const [quizMap, setQuizMap] = useState({})
  const [schemaSelected, setSchemaSelected] = useState()
  const [showTestInstructionsModal, setShowInstructionsModal] = useState(false)
  const [instructions, setIntructions] = useState([])
  const [showProcuctLinkModal, setShowProductLinkModal] = useState(false)

  let [courseMap, setCourseMap] = useState({})

  let showBlockModalVis = (val) => {
    if (val) {
      setSchemaName(undefined)
      setSchemaPic(undefined)
      setSlug(undefined)
      setDataSchema(undefined)
      setTempDataSchema(undefined)
      setTempUiSchema(undefined)
      setUISchema(undefined)
      setHideRenderedBlock(false)
      setOptionsBlockState(0)
    }
    setBlockModalVis(val)
  }

  let getAdmissionSchemaBlocks = () => {
    return new Promise((resolve, reject) => {
      Axios.get(`${BASE_URL}/cfpanelPlacement/gt_plcmnt_proc_blocks`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => {
          //showBlockModalVis(false)
          let basicDetailBlocks = response.data.data.basicDetailBlocks || []
          let educationalDetailBlocks = response.data.data.educationalDetailBlocks || []
          let professionalDetailBlocks = response.data.data.professionalDetailBlocks || []
          // setBlocks(applicationblocks)
          setBasicBlocks(basicDetailBlocks)
          setEducationalBlocks(educationalDetailBlocks)
          setProfessionalBlocks(professionalDetailBlocks)
          resolve(basicDetailBlocks)
        })
        .catch((err) => {
          if (err.response) return reject(err.response.data)
          else {
            console.log({ err })
            console.log(err.status)
            reject('Server error')
          }
        })
    })
  }

  let getAdmissionSchemas = () => {
    return new Promise((resolve, reject) => {
      Axios.get(`${BASE_URL}/cfpanelPlacement/gt_plcmnt_processes`, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => {
          //showBlockModalVis(false)
          let courseschemas = response.data.data.courseschemas || []
          setAppSchemas(courseschemas)
          resolve(courseschemas)
        })
        .catch((err) => {
          if (err.response) return reject(err.response.data)
          else {
            console.log({ err })
            console.log(err.status)
            reject('Server error')
          }
        })
    })
  }

  let getAdmissionSchemaBlock = (id) => {
    return new Promise((resolve, reject) => {
      Axios.post(
        `${BASE_URL}/cfpanelGeneric/gt_adm_proc_block`,
        {
          id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
        .then((response) => {
          let block = response.data.data.block || {}
          resolve(block)
        })
        .catch((err) => {
          if (err.response) return reject(err.response.data)
          else {
            console.log({ err })
            console.log(err.status)
            reject('Server error')
          }
        })
    })
  }

  let fetchUid = async () => {
    let { success, message, pl_id, u_idnt, idnt_id } = await getUID({
      crs_pg_id: course,
      type: 2,
    })
    console.log({ success, message, pl_id, u_idnt })
    console.log({ REACT_APP_BUILD_ENV: process.env.NODE_ENV })
    if (success == false) {
      if (message) {
        window.alert(message)
      } else {
        window.alert('Something went wrong. Please try again.')
      }
    } else {
      window.open(
        EXAM_DASHBOARD_URL + '?uid=' + u_idnt + '&plid=' + pl_id + '&ifa=5999'+'&idnt_id='+idnt_id,
        '_blank'
      )
    }
  }

  useEffect(() => {
    getAdmissionSchemas()
    getAdmissionSchemaBlocks()
  }, [])

  useEffect(() => {
    let newCourseMap = {}
    for (let i = 0; i < courses.length; i++) {
      newCourseMap[courses[i]._id] = courses[i]
    }
    setCourseMap(newCourseMap)
  }, [courses])

  useEffect(() => {
    let newSchemaMap = {}
    for (let i = 0; i < appSchemas.length; i++) {
      newSchemaMap[appSchemas[i].crs_pg_id] = true
    }
    setUsedCourseIds(newSchemaMap)
  }, [appSchemas])

  let createAdmissionSchemaBlock = () => {
    if (!validateAdmissionSchemaBlock()) {
      window.alert('Invalid Input')
      return
    }
    setIsLoading(true)
    new Promise((resolve, reject) => {
      const data = {
        schemaName,
        slug,
        dataSchema,
        schemaPic,
        uiSchema,
      }
      console.log({ data })
      Axios.post(`${BASE_URL}/cfpanelGeneric/crt_adm_proc_block`, data, {
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': cookies.get('at'),
          'Access-Control-Allow-Origin': '*',
        },
      })
        .then((response) => {
          showBlockModalVis(false)
          resolve(response.data)
        })
        .catch((err) => {
          if (err.response) return reject(err.response.data)
          else {
            console.log({ err })
            console.log(err.status)
            reject('Server error')
          }
        })
    })
      .then((res, err) => {
        setIsLoading(false)
        getAdmissionSchemaBlocks()
        console.log({ res, err })
      })
      .catch((err) => {
        console.log({ err })
        setIsLoading(false)
        window.alert('Invalid Input')
      })
  }

  let updateSchemaName = (val) => {
    setSchemaName(val)
  }

  let updateSchemaPic = (val) => {
    setSchemaPic(val)
  }

  let updateSlug = (val) => {
    setSlug(val)
  }

  let updateDataSchema = async (schema) => {
    setTempDataSchema(schema)
    let isValid = isValidSchema(schema)
    console.log({ isValid })
    if (isValid) setDataSchema(schema)
  }

  let updateUISchema = (schema) => {
    setTempUiSchema(schema)
    let isValid = isValidSchema(schema)
    console.log({ isValid })
    if (isValid) setUISchema(schema)
  }

  let appendBasicBlockSchema = async (e) => {
    let id = e.target.value
    let block = await getAdmissionSchemaBlock(id)
    block.isOpen = false
    setBasicBlocksUsed([...basicBlocksUsed, block])
  }

  let appendEducationalBlockSchema = async (e) => {
    let id = e.target.value
    let block = await getAdmissionSchemaBlock(id)
    block.isOpen = false
    setEducationalBlocksUsed([...educationalBlocksUsed, block])
  }

  let appendProfessionalBlockSchema = async (e) => {
    let id = e.target.value
    let block = await getAdmissionSchemaBlock(id)
    block.isOpen = false
    setProfessionalBlocksUsed([...professionalBlocksUsed, block])
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? 'lightgreen' : 'white',

    ...draggableStyle,
  })

  const grid = 8

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'grey' : 'lightgrey',
    padding: grid,
    width: `90%`,
    overflow: 'scroll',
    maxHeight: '40vh',
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  let onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    // setBlocksUsed(
    //   reorder(blocksUsed, result.source.index, result.destination.index)
    // )
  }

  if (isLoading) {
    return (
      <div className="Loader">
        <Loader active inline />
      </div>
    )
  }

  let deleteBlock = (id) => {
    // setBlocksUsed(blocksUsed.filter((block) => block._id != id))
  }

  let toggleBlock = (id) => {
    let newBlocks = []
    // for (let i = 0; i < blocksUsed.length; i++) {
    //   let tb = blocksUsed[i]
    //   if (tb._id == id) {
    //     tb.isOpen = !tb.isOpen
    //   }

    //   newBlocks.push(tb)
    // }

    // setBlocksUsed(newBlocks)
  }

  let validateAdmissionSchema = () => {
    // return course && courseMap[course] && blocksUsed.length > 0
  }

  let createAdmissionSchema = () => {
    // if (!validateAdmissionSchema()) return
    // let schema_objects = blocksUsed.map((block) => {
    //   return { blockId: block._id, type: block.type }
    // })
    // let data = {
    //   crs_pg_id: course,
    //   schema_objects,
    // }

    // new Promise((resolve, reject) => {
    //   Axios.post(
    //     `${BASE_URL}/cfpanelCourse/${
    //       isEdit ? 'update_adm_proc' : 'create_adm_proc'
    //     }`,
    //     data,
    //     {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         'x-access-token': cookies.get('at'),
    //         'Access-Control-Allow-Origin': '*',
    //       },
    //     }
    //   )
    //     .then((response) => {
    //       setMain(true)
    //       getAdmissionSchemas()
    //       window.alert('Course Admission Flow set Succesfully!')
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         console.log({ err })
    //         return reject(err.response.data)
    //       } else {
    //         console.log({ err })
    //         console.log(err.status)
    //         alert(err.data.message)
    //         reject('Server error')
    //       }
    //     })
    // })
  }

  let createNewProcessPress = () => {
    setMain(!main)
    setIsEdit(false)
    // setBlocksUsed([])
  }

  let editProcessPress = async (appSchemaId) => {
    setMain(!main)
    setIsEdit(true)
    setIsLoading(true)
    let appSchema = await getAdmissionSchema(appSchemaId)
    console.log({ appSchema })
    // setBlocksUsed(appSchema.schemas)
    setCourse(appSchema.crs_pg_id)
    setAppBlockSchemData(appSchema.app_block_schema_ids)
    setQuizMap(appSchema.quizMap)
    // setCourseGid(appSchemaGid)
    setIsLoading(false)
  }

  let quizUpdateRefresh = async (appSchemaId) => {
    setIsLoading(true)
    let appSchema = await getAdmissionSchema(appSchemaId)
    setAppBlockSchemData(appSchema.app_block_schema_ids)
    setQuizMap(appSchema.quizMap)
    setIsLoading(false)
  }

  let instructionsUpdateRefresh = async (appSchemaId) => {
    setIsLoading(true)
    let appSchema = await getAdmissionSchema(appSchemaId)
    setAppBlockSchemData(appSchema.app_block_schema_ids)
    setIsLoading(false)
  }

  let getAddedQuizzesOfTheBlock = (id) => {
    const quizzes = appBlockSchemData.filter((data) => data.blockId == id)[0]
      .quizzes
    setAddedQuizzes(quizzes)
  }

  let getInstructionsOfTheBlock = (id) => {
    const instructions = appBlockSchemData.filter(
      (data) => data.blockId == id
    )[0].instructions
    setIntructions(instructions)
  }

  if (main) {
    return (
        <div className='placement-portal'>
        <>
        <Sidebar active={7} />
      <div className="labs-main">
        <div className="process-section-center">
          <button onClick={createNewProcessPress} className="new-process-btn">
            + Create New Process
          </button>
        </div>
        <div className="process-section-center">
          <div className="process-line"></div>
        </div>
        <div className="process-section-left" style={{ width: '90%' }}>
          <div className="process-title">Existing Processes</div>
        </div>

        <div style={{ overflowX: 'hidden', overflowY: 'auto', width: '95%', height: "70vh" }}>
          {appSchemas &&
            courseMap &&
            Object.keys(courseMap).length > 0 &&
            appSchemas.map((appSchema) => {
              console.log(appSchema, courseMap, courseMap[appSchema.crs_pg_id])
              return (
                <Row
                  className="process-block"
                  onClick={() => {
                    setSchemaSelected(appSchema._id)
                    editProcessPress(appSchema._id)
                  }}
                  style={{ width: '100%', paddingLeft: "8px" }}
                >
                  <div>{courseMap[appSchema.crs_pg_id].name}</div>
                </Row>
              )
            })}
        </div>
      </div>
      </>
      </div>
    )
  } else {
    return (
    <div className='placement-portal'>
        <>
        <Sidebar active={7} />
        <div className="labs-main">
            {blockModalVis && (
            <div className="new-block-modal">
                <div className="process-main new-block-modal-content">
                <div className="process-section-center">
                    <div className="process-title">Create New Process Block</div>
                </div>
                <div className="process-section-center">
                    <div className="process-line" style={{ width: '100%' }}></div>
                </div>
                <div
                    className="process-new-block-container"
                    style={{ width: '100%' }}
                >
                    <div
                    className="process-section-left"
                    style={{
                        flexDirection: 'column',
                        display: optionsBlockState == 2 ? 'none' : 'flex',
                    }}
                    >
                    <div className="process-input-title">Process Name</div>
                    <input
                        className="process-input-text"
                        value={schemaName}
                        onChange={(e) => {
                        updateSchemaName(e.target.value)
                        }}
                    />
                    <div className="process-input-title">Process Unique ID</div>
                    <input
                        className="process-input-text"
                        value={slug}
                        onChange={(e) => {
                        updateSlug(e.target.value)
                        }}
                    />
                    <div className="process-input-title">Process Icon</div>
                    <input
                        className="process-input-text"
                        value={schemaPic}
                        onChange={(e) => {
                        updateSchemaPic(e.target.value)
                        }}
                    />
                    {optionsBlockState == 1 && (
                        <>
                        <div className="process-input-title">Data Schema</div>
                        <textarea
                            className="process-input-textarea"
                            value={tempDataSchema}
                            onChange={(e) => {
                            updateDataSchema(e.target.value)
                            }}
                        />
                        <div className="process-input-title">UI Schema</div>
                        <textarea
                            className="process-input-textarea"
                            value={tempUiSchema}
                            onChange={(e) => {
                            updateUISchema(e.target.value)
                            }}
                        />
                        </>
                    )}
                    {optionsBlockState == 0 && (
                        <FormBuilder
                        schema={dataSchema}
                        uischema={uiSchema}
                        onChange={(newSchema, newUiSchema) => {
                            updateDataSchema(newSchema)
                            updateUISchema(newUiSchema)
                        }}
                        />
                    )}
                    </div>
                    <div
                    className="process-section-left"
                    style={{
                        flexDirection: 'column',
                        height: '100%',
                        display: hideRenderedBlock == true ? 'none' : 'flex',
                    }}
                    >
                    <Form
                        schema={JSON.parse(dataSchema || '{}')}
                        uiSchema={JSON.parse(uiSchema || '{}')}
                        validator={validator}
                        liveValidate
                    />
                    </div>
                </div>
                <div className="process-section-center">
                    <div className="process-line" style={{ width: '100%' }}></div>
                </div>
                <div
                    className="process-section-center"
                    style={{ justifyContent: 'space-evenly' }}
                >
                    <button
                    className="new-process-btn"
                    onClick={() => {
                        createAdmissionSchemaBlock()
                    }}
                    >
                    Create
                    </button>
                    <button
                    className="new-process-btn"
                    onClick={() => {
                        setOptionsBlockState((optionsBlockState + 1) % 3)
                    }}
                    >
                    {optionsBlockState == 0
                        ? 'Show Schema'
                        : optionsBlockState == 1
                        ? 'Hide Options'
                        : 'Show Builder'}
                    </button>
                    <button
                    className="new-process-btn"
                    onClick={() => {
                        setHideRenderedBlock(!hideRenderedBlock)
                    }}
                    >
                    {hideRenderedBlock ? 'Show Form' : 'Hide Form'}
                    </button>
                    <button
                    className="new-process-btn red"
                    onClick={() => {
                        showBlockModalVis(false)
                    }}
                    >
                    Cancel
                    </button>
                </div>
                </div>
            </div>
            )}
            <div
            className="process-section-center"
            style={{ justifyContent: 'space-evenly' }}
            >
            <select
                className="form-control as-dl-ovrrd-sl"
                id="overrideSelectCourse"
                onChange={appendBasicBlockSchema}
                style={{ width: '50%' }}
            >
                <option key={''} className="dropdown-item " value={''} hidden>
                Select Basic Details Block
                </option>
                {basicBlocks.map((block, idx) => (
                <option
                    key={block._id}
                    className="dropdown-item "
                    value={block._id}
                >
                    {block.schemaName}
                </option>
                ))}
            </select>
            </div>
            <div
            className="process-section-center"
            style={{ justifyContent: 'space-evenly' }}
            >
            <select
                className="form-control as-dl-ovrrd-sl"
                id="overrideSelectCourse"
                onChange={appendEducationalBlockSchema}
                style={{ width: '50%' }}
            >
                <option key={''} className="dropdown-item " value={''} hidden>
                Select Educational Details Block
                </option>
                {educationalBlocks.map((block, idx) => (
                <option
                    key={block._id}
                    className="dropdown-item "
                    value={block._id}
                >
                    {block.schemaName}
                </option>
                ))}
            </select>
            </div>
            <div
            className="process-section-center"
            style={{ justifyContent: 'space-evenly' }}
            >
            <select
                className="form-control as-dl-ovrrd-sl"
                id="overrideSelectCourse"
                onChange={appendProfessionalBlockSchema}
                style={{ width: '50%' }}
            >
                <option key={''} className="dropdown-item " value={''} hidden>
                Select Professional Details Block
                </option>
                {professionalBlocks.map((block, idx) => (
                <option
                    key={block._id}
                    className="dropdown-item "
                    value={block._id}
                >
                    {block.schemaName}
                </option>
                ))}
            </select>
            </div>
            <div className="process-section-center">
            <div className="process-line"></div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
            <br />
            <Row>
            </Row>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {basicBlocksUsed.length > 0 ? (
                    basicBlocksUsed.map((item, index) => (
                        <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                        >
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                            <Row>
                                <Col xs="6" id={'TooltipExample' + index}>
                                <Button color="link" className="chapterName">
                                    {item.schemaName}
                                </Button>
                                </Col>
                                <ButtonGroup>
                                {item.type == 1 && (
                                    <Col
                                    sm={{
                                        size: 'auto',
                                    }}
                                    >
                                    <Button
                                        outline
                                        onClick={() => {
                                        toggleBlock(item._id)
                                        }}
                                        size="sm"
                                    >
                                        {' '}
                                        {item.isOpen ? 'Hide' : 'Show'}
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 1 && (
                                    <Col>
                                    {/* <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        console.log({
                                            data: JSON.stringify(
                                            item.Edituischema,
                                            null,
                                            4
                                            ),
                                            ui: JSON.stringify(
                                            item.Viewuischema,
                                            null,
                                            4
                                            ),
                                        })
                                        showBlockModalVis(true)
                                        updateDataSchema(
                                            JSON.stringify(
                                            item.Edituischema,
                                            null,
                                            4
                                            )
                                        )
                                        updateUISchema(
                                            JSON.stringify(
                                            item.Viewuischema,
                                            null,
                                            4
                                            )
                                        )
                                        updateSchemaName(item.schemaName)
                                        updateSlug(item.stageSlug)
                                        updateSchemaPic(item.pic)
                                        }}
                                    >
                                        {' '}
                                        Duplicate{' '}
                                    </Button> */}
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col
                                    sm={{
                                        size: 'auto',
                                    }}
                                    >
                                    <Button outline onClick={fetchUid} size="sm">
                                        {' '}
                                        Create Test
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        setActiveBlockId(item._id)
                                        getAddedQuizzesOfTheBlock(item._id)
                                        setShowTestModal(true)
                                        }}
                                    >
                                        {' '}
                                        Add Test{' '}
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        setActiveBlockId(item._id)
                                        getInstructionsOfTheBlock(item._id)
                                        setShowInstructionsModal(true)
                                        }}
                                    >
                                        {' '}
                                        Instructions
                                    </Button>
                                    </Col>
                                )}
                                <Col>
                                    <Button
                                    outline
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        if (
                                        window.confirm(
                                            'Are you sure you wish to delete this item?'
                                        )
                                        ) {
                                        deleteBlock(item._id)
                                        } else {
                                        console.log('cancelled!')
                                        }
                                    }}
                                    >
                                    {' '}
                                    Delete{' '}
                                    </Button>
                                </Col>
                                </ButtonGroup>
                            </Row>
                            {item.isOpen && (
                                <Row>
                                <Form
                                    schema={item.Edituischema}
                                    uiSchema={item.Viewuischema}
                                    children={true}
                                    validator={validator}
                                    liveValidate
                                    disabled
                                />
                                </Row>
                            )}
                            </div>
                        )}
                        </Draggable>
                    ))
                    ) : (
                    <h4>NO ITEMS</h4>
                    )}
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
            <div className="process-line"></div>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {educationalBlocksUsed.length > 0 ? (
                    educationalBlocksUsed.map((item, index) => (
                        <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                        >
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                            <Row>
                                <Col xs="6" id={'TooltipExample' + index}>
                                <Button color="link" className="chapterName">
                                    {item.schemaName}
                                </Button>
                                </Col>
                                <ButtonGroup>
                                {item.type == 1 && (
                                    <Col
                                    sm={{
                                        size: 'auto',
                                    }}
                                    >
                                    <Button
                                        outline
                                        onClick={() => {
                                        toggleBlock(item._id)
                                        }}
                                        size="sm"
                                    >
                                        {' '}
                                        {item.isOpen ? 'Hide' : 'Show'}
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 1 && (
                                    <Col>
                                    {/* <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        console.log({
                                            data: JSON.stringify(
                                            item.Edituischema,
                                            null,
                                            4
                                            ),
                                            ui: JSON.stringify(
                                            item.Viewuischema,
                                            null,
                                            4
                                            ),
                                        })
                                        showBlockModalVis(true)
                                        updateDataSchema(
                                            JSON.stringify(
                                            item.Edituischema,
                                            null,
                                            4
                                            )
                                        )
                                        updateUISchema(
                                            JSON.stringify(
                                            item.Viewuischema,
                                            null,
                                            4
                                            )
                                        )
                                        updateSchemaName(item.schemaName)
                                        updateSlug(item.stageSlug)
                                        updateSchemaPic(item.pic)
                                        }}
                                    >
                                        {' '}
                                        Duplicate{' '}
                                    </Button> */}
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col
                                    sm={{
                                        size: 'auto',
                                    }}
                                    >
                                    <Button outline onClick={fetchUid} size="sm">
                                        {' '}
                                        Create Test
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        setActiveBlockId(item._id)
                                        getAddedQuizzesOfTheBlock(item._id)
                                        setShowTestModal(true)
                                        }}
                                    >
                                        {' '}
                                        Add Test{' '}
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        setActiveBlockId(item._id)
                                        getInstructionsOfTheBlock(item._id)
                                        setShowInstructionsModal(true)
                                        }}
                                    >
                                        {' '}
                                        Instructions
                                    </Button>
                                    </Col>
                                )}
                                <Col>
                                    <Button
                                    outline
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        if (
                                        window.confirm(
                                            'Are you sure you wish to delete this item?'
                                        )
                                        ) {
                                        deleteBlock(item._id)
                                        } else {
                                        console.log('cancelled!')
                                        }
                                    }}
                                    >
                                    {' '}
                                    Delete{' '}
                                    </Button>
                                </Col>
                                </ButtonGroup>
                            </Row>
                            {item.isOpen && (
                                <Row>
                                <Form
                                    schema={item.Edituischema}
                                    uiSchema={item.Viewuischema}
                                    children={true}
                                    validator={validator}
                                    liveValidate
                                    disabled
                                />
                                </Row>
                            )}
                            </div>
                        )}
                        </Draggable>
                    ))
                    ) : (
                    <h4>NO ITEMS</h4>
                    )}
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
            <div className="process-line"></div>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {professionalBlocksUsed.length > 0 ? (
                    professionalBlocksUsed.map((item, index) => (
                        <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                        >
                        {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                            >
                            <Row>
                                <Col xs="6" id={'TooltipExample' + index}>
                                <Button color="link" className="chapterName">
                                    {item.schemaName}
                                </Button>
                                </Col>
                                <ButtonGroup>
                                {item.type == 1 && (
                                    <Col
                                    sm={{
                                        size: 'auto',
                                    }}
                                    >
                                    <Button
                                        outline
                                        onClick={() => {
                                        toggleBlock(item._id)
                                        }}
                                        size="sm"
                                    >
                                        {' '}
                                        {item.isOpen ? 'Hide' : 'Show'}
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 1 && (
                                    <Col>
                                    {/* <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        console.log({
                                            data: JSON.stringify(
                                            item.Edituischema,
                                            null,
                                            4
                                            ),
                                            ui: JSON.stringify(
                                            item.Viewuischema,
                                            null,
                                            4
                                            ),
                                        })
                                        showBlockModalVis(true)
                                        updateDataSchema(
                                            JSON.stringify(
                                            item.Edituischema,
                                            null,
                                            4
                                            )
                                        )
                                        updateUISchema(
                                            JSON.stringify(
                                            item.Viewuischema,
                                            null,
                                            4
                                            )
                                        )
                                        updateSchemaName(item.schemaName)
                                        updateSlug(item.stageSlug)
                                        updateSchemaPic(item.pic)
                                        }}
                                    >
                                        {' '}
                                        Duplicate{' '}
                                    </Button> */}
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col
                                    sm={{
                                        size: 'auto',
                                    }}
                                    >
                                    <Button outline onClick={fetchUid} size="sm">
                                        {' '}
                                        Create Test
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        setActiveBlockId(item._id)
                                        getAddedQuizzesOfTheBlock(item._id)
                                        setShowTestModal(true)
                                        }}
                                    >
                                        {' '}
                                        Add Test{' '}
                                    </Button>
                                    </Col>
                                )}
                                {item.type == 3 && (
                                    <Col>
                                    <Button
                                        outline
                                        size="sm"
                                        onClick={() => {
                                        setActiveBlockId(item._id)
                                        getInstructionsOfTheBlock(item._id)
                                        setShowInstructionsModal(true)
                                        }}
                                    >
                                        {' '}
                                        Instructions
                                    </Button>
                                    </Col>
                                )}
                                <Col>
                                    <Button
                                    outline
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                        if (
                                        window.confirm(
                                            'Are you sure you wish to delete this item?'
                                        )
                                        ) {
                                        deleteBlock(item._id)
                                        } else {
                                        console.log('cancelled!')
                                        }
                                    }}
                                    >
                                    {' '}
                                    Delete{' '}
                                    </Button>
                                </Col>
                                </ButtonGroup>
                            </Row>
                            {item.isOpen && (
                                <Row>
                                <Form
                                    schema={item.Edituischema}
                                    uiSchema={item.Viewuischema}
                                    children={true}
                                    validator={validator}
                                    liveValidate
                                    disabled
                                />
                                </Row>
                            )}
                            </div>
                        )}
                        </Draggable>
                    ))
                    ) : (
                    <h4>NO ITEMS</h4>
                    )}
                    {provided.placeholder}
                </div>
                )}
            </Droppable>
            </DragDropContext>
            <div className="process-section-center">
            <div className="process-line"></div>
            </div>

            <div
            className="process-section-center"
            style={{ justifyContent: 'space-evenly' }}
            >
            {!isEdit && (
                <select
                className="form-control as-dl-ovrrd-sl"
                id="overrideSelectCourse"
                onChange={(e) => setCourse(e.target.value)}
                style={{ width: '30%' }}
                >
                <option className="dropdown-item " hidden>
                    {'Select Course Name'}
                </option>
                {courses
                    .filter((course) => !usedCourseIds[course._id])
                    .map((crs, key) => (
                    <option key={key} className="dropdown-item " value={crs._id}>
                        {crs.name}
                    </option>
                    ))}
                </select>
            )}
            <button
                className="new-process-btn"
                style={{ paddingLeft: '48px', paddingRight: '48px' }}
                onClick={() => {
                createAdmissionSchema()
                }}
            >
                Submit
            </button>
            <button
                className="new-process-btn red"
                style={{ paddingLeft: '48px', paddingRight: '48px' }}
                onClick={() => {
                setMain(true)
                }}
            >
                Cancel
            </button>
            </div>
        </div>
      </>
    </div>
    )
  }
}

export default PlacementSettings
