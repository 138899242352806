import React from 'react'
import { history } from '../../..'
import { connect } from 'react-redux' 

const Sidebar = ({ active }) => {

  let props = {}
  const checkVisibility = (resource,resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <div className="labs-sidebar">
      {checkVisibility('43', 1) && (
      <div
        className={
          active === 1
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services')
        }}
      >
        Job Postings
      </div>
      )}
      {checkVisibility('42', 1) && (
      <div
        className={
          active === 2
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/application-status')
        }}
      >
        Application Status
      </div>
      )}

      {/* <div
        className={
          active === 3
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/pending-job-reviews')
        }}
      >
        Pending Job Reviews
      </div> */}

      {/* <div
        className={
          active === 4
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/exception-approval-requests')
        }}
      >
        Exception Approval Requests
      </div> */}
      {/* <div
        className={
          active === 5
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/resume-reviews')
        }}
      >
        Resume Reviews
      </div> */}
      {checkVisibility('47', 1) && (
      <div
        className={
          active === 6
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/manage-learners')
        }}
      >
        Manage Learners
      </div>
      )}
      {/* {checkVisibility('46', 1) && (
      <div
        className={
          active === 7
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/placement-settings')
        }}
      >
        Placement Settings
      </div>
      )} */}
      <div
        className={
          active === 8
            ? 'labs-sidebar-elem labs-sidebar-active'
            : `labs-sidebar-elem`
        }
        onClick={() => {
          history.push('/career-services/placement-companies')
        }}
      >
        Placement Companies
      </div>
    </div>
  )
}

// export default Sidebar
const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(Sidebar)