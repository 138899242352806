import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { Col, Row } from 'react-bootstrap'
import '../../LabsManagement.css'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { history } from '../../../..'
import { Loader } from 'semantic-ui-react'
import moment from 'moment-timezone'
const configs = require('../../../../config/configs.js')

const AddLabsCost = () => {
  const [cloud, setCloud] = useState({})
  const [ram, setRam] = useState({})
  const [region, setRegion] = useState('')
  const [vcpu, setVcpu] = useState('')
  const [type, setType] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [formdata, setFormdata] = useState({
    cloud_provider: '',
    region: '',
    ram: '',
    price_per_hour: '',
    valid_from: '',
    valid_till: '',
    vcpu: '',
  })

  const timezone = 'Asia/Calcutta'

  const submitHanlder = (e) => {
    const cookies = new Cookies()
    e.preventDefault()

    try {
      setIsLoading(true)
      const createLabsRequest = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          ...formdata,
          valid_from: moment.tz(formdata.valid_from, timezone).valueOf(),
          valid_till: moment.tz(formdata.valid_till, timezone).valueOf(),
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/addCost`,
          data,
          config
        )

        if (res) {
          if (res.status === 200) {
            history.push('/labs-management/cost')
          } else {
            alert(res.data.message)
          }
        }
      }
      createLabsRequest()
      setIsLoading(false)
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const fetchConstants = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          admin_aid: cookies.get('aid'),
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getConstants`,
          data,
          config
        )

        if (res.data.data) {
          const res1 = res.data.data
          setCloud(res1.cloudProvider)
          setRam(res1.RAM)
          setVcpu(res1.vCPU)
          setRegion(res1.CLOUD_PROVIDER_REGION)
        }

        setIsLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    fetchConstants()
  }, [])

  const upadteFormdata = (e) => {
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={3} />
      <div className="labs-main" style={{ paddingBottom: '10vh' }}>
        <TopBar />
        <div className="labs-content">
          <div>
            <div className="labs-create-new-header">Add Cost</div>
            <div>
              <b>NOTE: </b> &nbsp; Please do not create Cost without
              teach-team's permission.
            </div>
            <hr />
            <form onSubmit={(e) => submitHanlder(e)}>
              <Row>
                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Cloud Provider</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="cloud_provider"
                      onChange={upadteFormdata}
                    >
                      <option value="">Select Cloud Provider</option>
                      {cloud
                        ? Object.keys(cloud).map((key, i) => {
                            return (
                              <option value={cloud[key]} key={i}>
                                {key}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Region</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="region"
                      onChange={upadteFormdata}
                    >
                      <option value="">Select Region</option>
                      {region
                        ? Object.keys(region).map((key, i) => {
                            return (
                              <option value={key} key={i}>
                                {region[key]}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Instance/Storage</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      value={type}
                      name="ins/str"
                      onChange={(e) => {
                        setType(e.target.value)
                      }}
                    >
                      <option value={1}>Instance</option>
                      <option value={2}>Storage</option>
                    </select>
                  </div>
                </Col>

                {Number(type) === 1 && (
                  <>
                    <Col md={3}>
                      <div className="labs-create-new-form">
                        <div>Select RAM</div>
                        <select
                          className="labs-create-new-form-text_input"
                          required={true}
                          name="ram"
                          onChange={upadteFormdata}
                        >
                          <option value="">Select Minimum RAM</option>
                          {ram
                            ? Object.keys(ram).map((key, i) => {
                                return (
                                  <option value={key} key={i}>
                                    {ram[key]}
                                  </option>
                                )
                              })
                            : null}
                        </select>
                      </div>
                    </Col>

                    <Col md={3}>
                      <div className="labs-create-new-form">
                        <div>Select vcpu</div>
                        <select
                          className="labs-create-new-form-text_input"
                          required={true}
                          name="vcpu"
                          onChange={upadteFormdata}
                        >
                          <option value="">Select vcpu</option>
                          {vcpu
                            ? Object.keys(vcpu).map((key, i) => {
                                return (
                                  <option value={vcpu[key]} key={i}>
                                    {vcpu[key]}
                                  </option>
                                )
                              })
                            : null}
                        </select>
                      </div>
                    </Col>
                  </>
                )}

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Price Per Hour (In USD only)</div>
                    <input
                      className="labs-create-new-form-text_input"
                      required={true}
                      type="number"
                      name="price_per_hour"
                      onChange={upadteFormdata}
                      step="any"
                    ></input>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Pricing Validity Starting</div>
                    <input
                      type="datetime-local"
                      className="labs-create-new-form-text_input"
                      name="valid_from"
                      value={formdata.valid_from}
                      required={true}
                      onChange={upadteFormdata}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Price Validity Ending</div>
                    <input
                      type="datetime-local"
                      className="labs-create-new-form-text_input"
                      name="valid_till"
                      value={formdata.valid_till}
                      required={true}
                      onChange={upadteFormdata}
                      min={formdata.valid_from}
                    />
                  </div>
                </Col>

                <Col md={12}>
                  <Col md={2}>
                    <button className="labs-table-btn-1" type="submit">
                      Create Cost
                    </button>
                  </Col>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddLabsCost
