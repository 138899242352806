import React from 'react';
import './LoadingOverlay.css'; // Import the CSS file for styling

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) return null;

  return (
    <div className="overlay">
      <div className="spinner-container">
        <div className="spinner"></div>
        <img src={"https://cdn.pegasus.imarticus.org/imarticus_2/footerlogo.svg"} alt="Brand Logo" className="brand-logo" />
      </div>
    </div>
  );
};

export default LoadingOverlay;