import { useEffect, useState } from 'react'
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material'
import { Modal, ModalHeader, ModalBody, ModalFooter, Table } from 'reactstrap'
import { Loader, TableRow } from 'semantic-ui-react'
import Axios from 'axios'
import { routes } from '../../../config/configs'
import moment from 'moment'
import RenderMenu from '../../../Components/PDFReader/PDFReader'

const TrackAgreements = () => {
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [crs_pgs, setCrs_pg] = useState([])
  const [students, setStudents] = useState([])
  const [courses, setCourses] = useState([])
  const [categories, setCategories] = useState([])
  const [agreements, setAgreements] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const [retrigger, setRetrigger] = useState(false)
  const [retrigger2, setRetrigger2] = useState(false)
  const [preview, setPreview] = useState(false)

  const [reAgree, setReagree] = useState({})
  const [reCategory, setRecategory] = useState({})
  const [reStudent, setRestudent] = useState({})

  const [s_crs_pg, set_s_crs_pg] = useState('')
  const [s_course, sets_course] = useState('')
  const [s_cat, sets_cat] = useState('')
  const [s_agree, sets_agree] = useState('')
  const [s_resp, sets_resp] = useState('')

  const [respList, setRespList] = useState([])
  const [selectionCount, setSelectionCount] = useState(0)

  const [success, setSuccess] = useState(false)
  const [search, setSearch] = useState('')
  const [trigger, setTrigger] = useState(false)

  const handleSelection = (id) => {
    if (respList.includes(id)) {
      const updatedSelection = respList.filter(
        (selectedId) => selectedId !== id
      )
      setRespList(updatedSelection)
    } else {
      setRespList([...respList, id])
    }
  }

  const handleSelectAll = () => {
    if (respList.length === selectionCount) {
      setRespList([])
      return
    }
    const temp = []

    students.forEach((elem) => {
      if (elem?.response?.response === 2) {
        temp.push(elem.response?._id)
      }
    })

    setRespList(temp)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    setIsLoading(true)
    const getRequests = async () => {
      try {
        const baseURL = `${routes.BASE_URL_DEVELOPMENT}/cfpanelGeneric/get_all_pub_agreement`
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': '',
          },
        }

        const data = {
          page,
          rowsPerPage,
          crs_pg_id: s_crs_pg,
          crs_id: s_course,
          cat_id: s_cat,
          agree_id: s_agree,
          response: s_resp,
          search: search,
        }

        const res = await Axios.post(baseURL, data, config)

        if (res.data.data) {
          const value = res.data.data
          setCrs_pg(value.crs_pgs)
          setStudents(value.students)
          setCategories(value.categories)
          setCourses(value.courses)
          setAgreements(value.agreements)
          setTotalCount(value.totalCount)
          setReagree(value.selectedAgreement)
          setRecategory(value.selectedCategory)
          setRespList([])
          const temp = []
          value?.students?.forEach((elem) => {
            if (elem?.response?.response === 2) {
              temp.push(elem.response?._id)
            }
          })
          setSelectionCount(temp.length)
        }
      } catch (error) {
        console.error(error.message)
      }
      setIsLoading(false)
    }
    getRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, s_crs_pg, s_course, s_cat, s_agree, s_resp, trigger])

  console.log(respList, selectionCount)

  const retriggerAgreements = async (singleId) => {
    setIsLoading(true)

    try {
      const baseURL = `${routes.BASE_URL_DEVELOPMENT}/cfpanelGeneric/retrigger_agreement`
      const config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': '',
        },
      }

      const data = {
        response_list: singleId,
      }

      const res = await Axios.post(baseURL, data, config)

      if (res.data.data) {
        setSuccess(true)
        setRetrigger(false)
        setRetrigger2(false)
      }
    } catch (error) {
      console.error(error.message)
    }
    setIsLoading(false)
  }

  const expStudents = async () => {
    setIsLoading(true)

    try {
      const baseURL = `${routes.BASE_URL_DEVELOPMENT}/cfpanelGeneric/exp_student_agreements?agree_id=${s_agree}&crs_id=${s_course}&crs_pg_id=${s_crs_pg}&response=${s_resp}`
      const config = {
        responseType: 'blob',
      }

      const res = await Axios.get(baseURL, config)

      if (res.status === 200) {
        downloadFile(res)
      }
    } catch (error) {
      console.error(error.message)
    }
    setIsLoading(false)
  }

  const downloadFile = (res) => {
    const contentDisposition = res.headers['content-disposition']
    let fileName = contentDisposition.split(';')[1].split('=')[1]

    console.log('File name : ', fileName)
    fileName = fileName.replaceAll('"', '')
    console.log('File name : ', fileName)

    const url = window.URL.createObjectURL(new Blob([res.data]))
    const link = document.createElement('a')
    link.href = url

    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div>
      <div
        style={{
          zIndex: '2',
          position: 'absolute',
          color: 'white',
          fontSize: '16px',
          fontWeight: '600',
          top: '100px',
          left: '24px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onClick={() => {
          window.history.back()
        }}
      >
        <img
          src="https://cdn.pegasus.imarticus.org/imarticus_2/arrow.svg"
          alt="back"
          style={{
            transform: 'rotate(180deg)',
            marginRight: '10px',
          }}
        ></img>
        <span>Back</span>
      </div>
      <div
        style={{
          padding: '3rem 4rem',
          marginLeft: '250px',
          fontFamily: 'Source Sans 3',
        }}
      >
        <Modal isOpen={success} style={{ width: '50%', marginTop: '30vh' }}>
          <ModalBody>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <span></span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setSuccess(false)
                  }}
                />
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '1rem',
              }}
            >
              <div
                style={{
                  color: '#A09FA0',
                  fontSize: '24px',
                  textTransform: 'uppercase',
                  fontWeight: 700,
                }}
              >
                Retrigger successful!
              </div>

              <img
                style={{ marginTop: '1rem' }}
                src="https://cdn.pegasus.imarticus.org/courses/images/success_tick.svg"
                alt=""
              />
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={retrigger2}
          style={{
            width: '50%',
          }}
          centered
        >
          <ModalHeader>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '48vw',
              }}
            >
              <span style={{ fontSize: '14px', fontWeight: '600' }}>
                Retrigger Agreement
              </span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setRetrigger2(false)
                  }}
                />
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                textAlign: 'center',
                color: '#A09FA0',
                fontSize: '24px',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            >
              Do you want to retrigger this agreement?
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '1rem',
              }}
            >
              <span>Agreement Details &nbsp;</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Agreement: &nbsp;</span>
              <span style={{ color: '#035642' }}>{reCategory?.name}</span>&nbsp;
              &nbsp;
              <button
                style={{
                  background: 'rgba(5, 86, 70, 0.10)',
                  color: '#035642',
                  border: 'solid 1px ',
                  borderRadius: '5px',
                  padding: '0.4rem',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setPreview(true)
                }}
              >
                Preview
              </button>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Retrigger Date & Time: &nbsp;</span>
              <span style={{ color: '#035642' }}>
                {new Date().toLocaleDateString()},{' '}
                {new Date().toLocaleTimeString()}
              </span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Learner Selected: &nbsp;</span>
              <span style={{ color: '#035642' }}>
                {respList.length} out of {selectionCount}
              </span>
            </div>
          </ModalBody>

          <div
            style={{
              padding: '2rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              style={{
                width: 'auto',
                padding: '12px 16px',
                background: '#035642',
                color: '#fff',
                borderRadius: '4px',
                border: 'none',
                marginRight: '1rem',
              }}
              onClick={() => {
                retriggerAgreements(respList)
              }}
            >
              Retrigger Agreement
            </button>

            <button
              style={{
                width: 'auto',
                padding: '4px 16px',
                background: '#6C757D',
                color: '#fff',
                borderRadius: '4px',
                border: 'none',
              }}
              onClick={() => {
                setRetrigger2(false)
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={retrigger}
          style={{
            width: '50%',
          }}
          centered
        >
          <ModalHeader>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '48vw',
              }}
            >
              <span style={{ fontSize: '14px', fontWeight: '600' }}>
                Retrigger Agreement
              </span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setRetrigger(false)
                  }}
                />
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div
              style={{
                textAlign: 'center',
                color: '#A09FA0',
                fontSize: '24px',
                fontWeight: '600',
                textTransform: 'uppercase',
              }}
            >
              Do you want to retrigger this agreement?
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '1rem',
              }}
            >
              <span>Learner Details &nbsp;</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Name: &nbsp;</span>
              <span style={{ color: '#035642' }}>{reStudent?.name}</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Learner_ID: &nbsp;</span>
              <span style={{ color: '#035642' }}>{reStudent?.uid}</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Batch: &nbsp;</span>
              <span style={{ color: '#035642' }}>{reStudent?.batch_name}</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Previous Response: &nbsp;</span>
              <span style={{ color: '#035642' }}>Declined</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Response Timestamp: &nbsp;</span>
              <span style={{ color: '#035642' }}>
                {moment
                  .utc(reStudent?.response?.updatedAt)
                  .tz('Asia/Kolkata')
                  .format('DD-MM-YY, HH:mm')}
              </span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Reason: &nbsp;</span>
              <span style={{ color: '#035642' }}>
                {reStudent?.response?.remarks}
              </span>
            </div>

            <hr />

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span>Agreement Details &nbsp;</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Agreement: &nbsp;</span>
              <span style={{ color: '#035642' }}>{reCategory?.name}</span>{' '}
              &nbsp; &nbsp;
              <button
                style={{
                  background: 'rgba(5, 86, 70, 0.10)',
                  color: '#035642',
                  border: 'solid 1px ',
                  borderRadius: '5px',
                  padding: '0.4rem',
                  fontSize: '12px',
                  fontWeight: '600',
                }}
                onClick={() => {
                  setPreview(true)
                }}
              >
                Preview
              </button>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '14px',
                margin: '0.5rem',
              }}
            >
              <span> Retrigger Date & Time: &nbsp;</span>
              <span style={{ color: '#035642' }}>
                {new Date().toLocaleDateString()},{' '}
                {new Date().toLocaleTimeString()}
              </span>
            </div>
          </ModalBody>

          <div
            style={{
              padding: '2rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              style={{
                width: 'auto',
                padding: '12px 16px',
                background: '#035642',
                color: '#fff',
                borderRadius: '4px',
                border: 'none',
                marginRight: '1rem',
              }}
              onClick={() => {
                retriggerAgreements([reStudent?.response._id])
              }}
            >
              Retrigger Agreement
            </button>

            <button
              style={{
                width: 'auto',
                padding: '4px 16px',
                background: '#6C757D',
                color: '#fff',
                borderRadius: '4px',
                border: 'none',
              }}
              onClick={() => {
                setRetrigger(false)
              }}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal isOpen={preview}>
          <ModalHeader>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '78vw',
              }}
            >
              <span>View Agreement</span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setPreview(false)
                  }}
                />
              </span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div>
              <RenderMenu height="50vh" url={reAgree?.doc_url} />
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '16px',
                margin: '1rem',
              }}
            >
              <span>Agreement Name: &nbsp;</span>
              <span style={{ color: '#035642' }}>{reCategory?.name}</span>
            </div>

            <div
              style={{
                fontWeight: '600',
                color: '#212A39',
                fontSize: '16px',
                margin: '1rem',
              }}
            >
              <span>Date of Upload: &nbsp;</span>
              <span style={{ color: '#035642' }}>
                {moment
                  .utc(reAgree?.createdAt)
                  .tz('Asia/Kolkata')
                  .format('DD-MM-YY, HH:mm')}
              </span>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              style={{
                width: '100px',
                padding: '4px 16px',
                background: '#035642',
                color: '#fff',
                borderRadius: '4px',
                border: 'none',
              }}
              onClick={() => {
                setPreview(false)
              }}
            >
              Cancel
            </button>
          </ModalFooter>
        </Modal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              color: '#595F6B',
              fontWeight: '600',
            }}
          >
            Track Agreements
          </div>

          {students.length > 0 && (
            <button
              style={{
                background: 'white',
              }}
              onClick={() => {
                expStudents()
              }}
            >
              Export Data
            </button>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <select
            style={{
              border: 'solid 1px #CED4DA',
              width: '18%',
              padding: '12px',
              background: '#fff',
            }}
            onChange={(e) => {
              set_s_crs_pg(e.target.value)
              sets_course('')
              sets_cat('')
              sets_agree('')
              sets_resp('')
            }}
            value={s_crs_pg}
          >
            <option value="">Select Course</option>
            {crs_pgs &&
              crs_pgs.map((elem, i) => {
                return (
                  <option value={elem._id} key={i}>
                    {elem.name}
                  </option>
                )
              })}
          </select>

          <select
            style={{
              border: 'solid 1px #CED4DA',
              width: '18%',
              padding: '12px',
              background: '#fff',
            }}
            onChange={(e) => {
              sets_course(e.target.value)
              sets_cat('')
              sets_agree('')
              sets_resp('')
            }}
            value={s_course}
          >
            <option value="">Select Batch</option>
            {courses &&
              courses.map((elem, i) => {
                return (
                  <option value={elem._id} key={i}>
                    {elem.btch_name}
                  </option>
                )
              })}
          </select>

          <select
            style={{
              border: 'solid 1px #CED4DA',
              width: '18%',
              padding: '12px',
              background: '#fff',
            }}
            value={s_cat}
            onChange={(e) => {
              sets_cat(e.target.value)
              sets_agree('')
              sets_resp('')
            }}
          >
            <option value="">Select Category</option>
            {categories &&
              categories.map((elem, i) => {
                return (
                  <option value={elem._id} key={i}>
                    {elem.name}
                  </option>
                )
              })}
          </select>

          <select
            style={{
              border: 'solid 1px #CED4DA',
              width: '18%',
              padding: '12px',
              background: '#fff',
            }}
            onChange={(e) => {
              sets_agree(e.target.value)
              sets_resp('')
            }}
            value={s_agree}
          >
            <option value="">Select Agreement </option>
            {agreements &&
              agreements.map((elem, i) => {
                return (
                  <option value={elem._id} key={i}>
                    {elem.name}
                  </option>
                )
              })}
          </select>

          <select
            style={{
              border: 'solid 1px #CED4DA',
              width: '18%',
              padding: '12px',
              background: '#fff',
            }}
            value={s_resp}
            onChange={(e) => {
              sets_resp(e.target.value)
            }}
          >
            <option value="">Filter Response </option>
            <option value={1}>Accepted </option>
            <option value={2}>Declined </option>
          </select>
        </div>
        {s_crs_pg && s_course && s_cat && s_agree && (
          <div
            style={{
              marginTop: '1rem',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <input
              type="text"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              placeholder="Search learner using Name, LearnerID..."
              value={search}
              style={{
                width: '80%',
                height: '40px',
                border: 'solid 1px',
                borderRadius: '4px',
                borderColor: '#CED4DA',
                padding: '11px',
                fontSize: '14px',
              }}
            />
            <div
              style={{
                width: '20%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <button
                onClick={() => {
                  setTrigger(!trigger)
                }}
                disabled={!search.trim().length}
                style={{
                  width: 'auto',
                  padding: '8px 16px',
                  background: '#035642',
                  color: '#fff',
                  borderRadius: '4px',
                  border: 'none',
                  height: '40px',
                  cursor: 'pointer',
                }}
              >
                Search
              </button>
              <button
                onClick={() => {
                  setSearch('')
                  setTrigger(!trigger)
                }}
                style={{
                  width: 'auto',
                  padding: '8px 16px',
                  background: '#6C757D',
                  color: '#fff',
                  borderRadius: '4px',
                  border: 'none',
                  height: '40px',
                  cursor: 'pointer',
                }}
              >
                Reset
              </button>
            </div>
          </div>
        )}
        {students && students.length > 0 ? (
          <div>
            <div>
              <TableContainer style={{ width: '100%', marginTop: '1rem' }}>
                <div>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </div>
                <Table className="table_p">
                  <TableHead className="table_head">
                    <TableCell
                      align="center"
                      style={{
                        border: 'solid 1px',
                        // position: 'sticky',
                        // left: '0',
                        // zIndex: '100',
                        background: '#e5e5e5',
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={() => {
                          handleSelectAll()
                        }}
                        checked={
                          respList.length === selectionCount &&
                          selectionCount > 0
                        }
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                        // position: 'sticky',
                        // left: '70px',
                        // zIndex: '100',
                        background: '#e5e5e5',
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                        // position: 'sticky',
                        // left: '200px',
                        // zIndex: '100',
                        background: '#e5e5e5',
                      }}
                      className=" trb"
                    >
                      Learner ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                        // position: 'sticky',
                        // left: '300px',
                        // zIndex: '100',
                        background: '#e5e5e5',
                      }}
                      className=" trb"
                    >
                      Batch ID
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                      }}
                      className=" trb"
                    >
                      Response
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                      }}
                      className=" trb"
                    >
                      Response Timestamp
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                      }}
                      className=" trb"
                    >
                      Reason
                    </TableCell>

                    <TableCell
                      align="center"
                      style={{
                        minWidth: '100px',
                        border: 'solid 1px',
                        fontWeight: '600',
                      }}
                      className=" trb"
                    >
                      Action
                    </TableCell>
                  </TableHead>
                  <TableBody>
                    {students &&
                      students.map((elem, i) => {
                        return (
                          <TableRow key={i}>
                            <TableCell
                              align="center"
                              style={{
                                border: 'solid 1px',
                                // position: 'sticky',
                                // left: '0px',
                                // zIndex: '100',
                                background: '#e5e5e5',
                              }}
                            >
                              <input
                                type="checkbox"
                                onChange={() => {
                                  handleSelection(elem.response._id)
                                }}
                                disabled={elem.response?.response !== 2}
                                checked={respList.includes(elem.response?._id)}
                              />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                minWidth: '100px',
                                border: 'solid 1px',

                                background: '#e5e5e5',
                              }}
                            >
                              {elem.name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                minWidth: '100px',
                                border: 'solid 1px',
                                // position: 'sticky',
                                // left: '200px',
                                // zIndex: '100',
                                background: '#e5e5e5',
                              }}
                              className=" trb"
                            >
                              {elem.uid}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                minWidth: '100px',
                                border: 'solid 1px',
                                // position: 'sticky',
                                // left: '300px',
                                // zIndex: '100',
                                background: '#e5e5e5',
                              }}
                              className=" trb"
                            >
                              {elem.batch_name}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ minWidth: '100px', border: 'solid 1px' }}
                              className=" trb"
                            >
                              {elem.response && elem.response?.response === 1
                                ? 'Accepted'
                                : elem.response?.response === 2
                                ? 'Decline'
                                : 'Pending Response'}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ minWidth: '100px', border: 'solid 1px' }}
                              className=" trb"
                            >
                              {elem.response
                                ? moment
                                    .utc(elem.response?.updatedAt)
                                    .tz('Asia/Kolkata')
                                    .format('DD-MM-YY, HH:mm')
                                : '-'}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ minWidth: '100px', border: 'solid 1px' }}
                              className=" trb"
                            >
                              {elem.response &&
                              elem.response?.response === 2 &&
                              elem.response?.remarks
                                ? elem.response?.remarks
                                : '-'}
                            </TableCell>

                            <TableCell
                              align="center"
                              style={{ minWidth: '100px', border: 'solid 1px' }}
                              className=" trb"
                            >
                              {elem.response &&
                              elem.response?.response === 1 ? (
                                '-'
                              ) : elem.response?.response === 2 ? (
                                <img
                                  src="https://cdn.pegasus.imarticus.org/courses/images/retrigger.svg"
                                  alt="close"
                                  onClick={() => {
                                    setRestudent(elem)
                                    setRetrigger(true)
                                  }}
                                  style={{ cursor: 'pointer' }}
                                />
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  marginTop: '1rem',
                }}
              >
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#035642',
                  }}
                >
                  {respList.length} selected
                </div>
                {respList.length > 0 && (
                  <div>
                    <button
                      style={{
                        width: 'auto',
                        padding: '12px 16px',
                        background: '#035642',
                        color: '#fff',
                        borderRadius: '4px',
                        border: 'none',
                        marginLeft: '1rem',
                      }}
                      disabled={!respList.length}
                      onClick={() => {
                        setRetrigger2(true)
                      }}
                    >
                      Retrigger Agreements
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginTop: '2rem',
              color: '#575E64',
            }}
          >
            Please select filters to get the data.
          </div>
        )}
      </div>
    </div>
  )
}

export default TrackAgreements
