const NODE_ENV = process.env.NODE_ENV ? process.env.NODE_ENV : ''

const BUILD_ENV = process.env.REACT_APP_BUILD_ENV
  ? process.env.REACT_APP_BUILD_ENV
  : 'local'

//localhost-------------
let _routes = {
  BASE_URL_DEVELOPMENT: 'http://localhost:3000',
  BASE_URL_PRODUCTION: 'http://localhost:3000',
  BASE_URL_TEST_SERVER: 'http://localhost:3008',
  TEST_SERVER_KEY: '1231238374893748937984789789789',
  TEST_SERVER_SECRET: '12320809oidbnofbdsof0y98y40893yh4bi340g394g3098v43v430v430v43043023209809i02noiboib32oi3b2o3g92836297409279043u2oi4b3oi4v3984f398v30o903u09480948093h903b4093b4093b4093b4',
  BASE_URL_API: 'http://localhost:3000/',
  ADOBE_PDF_READER_KEY: "23fb9c0e52ca4cc99ea6e62e8688eca3",
}

if (NODE_ENV == 'production' && BUILD_ENV == 'testing') {
  //testing------------
  _routes = {
    BASE_URL_DEVELOPMENT: 'https://apiht.pegasust.imarticus.org',
    BASE_URL_PRODUCTION: 'https://apiht.pegasust.imarticus.org',
    BASE_URL_TEST_SERVER: 'https://examt.pegasust.imarticus.org',
    TEST_SERVER_KEY: '1231238374893748937984789789789',
    TEST_SERVER_SECRET: '12320809oidbnofbdsof0y98y40893yh4bi340g394g3098v43v430v430v43043023209809i02noiboib32oi3b2o3g92836297409279043u2oi4b3oi4v3984f398v30o903u09480948093h903b4093b4093b4093b4',
    BASE_URL_API: 'https://apiht.pegasust.imarticus.org/api/',
    ADOBE_PDF_READER_KEY: "6475105e1e954b5d8dc0182099b441d0",
  }
} else if (NODE_ENV == 'production' && BUILD_ENV == 'staging') {
  //staging------------
  _routes = {
    BASE_URL_DEVELOPMENT: 'https://apihs.pegasuss.imarticus.org',
    BASE_URL_PRODUCTION: 'https://apihs.pegasuss.imarticus.org',
    BASE_URL_TEST_SERVER: 'https://exams.pegasuss.imarticus.org',
    TEST_SERVER_KEY: '1231238374893748937984789789789',
    TEST_SERVER_SECRET:
      '12320809oidbnofbdsof0y98y40893yh4bi340g394g3098v43v430v430v43043023209809i02noiboib32oi3b2o3g92836297409279043u2oi4b3oi4v3984f398v30o903u09480948093h903b4093b4093b4093b4',
    BASE_URL_API: 'https://apihs.pegasuss.imarticus.org/api/',
    ADOBE_PDF_READER_KEY: "6475105e1e954b5d8dc0182099b441d0",
  }
} else if (NODE_ENV == 'production' && BUILD_ENV == 'production') {
  //production------------
  _routes = {
    BASE_URL_DEVELOPMENT: 'https://apihteach.pegasus.imarticus.org',
    BASE_URL_PRODUCTION: 'https://apihteach.pegasus.imarticus.org',
    BASE_URL_TEST_SERVER: 'https://exam.pegasus.imarticus.org',
    TEST_SERVER_KEY: '1231238374893748937984789789789',
    TEST_SERVER_SECRET:
      '12320809oidbnofbdsof0y98y40893yh4bi340g394g3098v43v430v430v43043023209809i02noiboib32oi3b2o3g92836297409279043u2oi4b3oi4v3984f398v30o903u09480948093h903b4093b4093b4093b4',
    BASE_URL_API: 'https://apihteach.pegasus.imarticus.org/api/',
    ADOBE_PDF_READER_KEY: "6475105e1e954b5d8dc0182099b441d0",

  }
}

const _editorConfig = {
  attribution: false,
  placeholder: "Start typing...",
  toolbarButtons: {
    moreText: {
      buttons: [
        "bold",
        "fontSize",
        "underline",
        "italic",
        "strikeThrough",
        "subscript",
        "superscript",
        "fontFamily",
        "textColor",
        "backgroundColor",
        "inlineClass",
        "inlineStyle",
        "clearFormatting"
      ]
    },
    moreParagraph: {
      buttons: [
        "alignLeft",
        "alignCenter",
        "formatOLSimple",
        "alignRight",
        "alignJustify",
        "formatOL",
        "formatUL",
        "paragraphFormat",
        "paragraphStyle",
        "lineHeight",
        "outdent",
        "indent",
        "quote"
      ]
    },
    moreRich: {
      buttons: [
        "insertLink",
        // "insertImage",
        // "insertVideo",
        "insertTable",
        "fontAwesome",
        "specialCharacters",
        "embedly",
        "insertHR"
      ]
    },
    moreMisc: {
      buttons: [
        "undo",
        "redo",
        "fullscreen",
        "print",
        "getPDF",
        "spellChecker",
        "selectAll",
        "html",
        "help"
      ],
      align: "right",
      buttonsVisible: 2
    }
  },
  pluginsEnabled: [
    "table",
    "spell",
    "quote",
    "save",
    "paragraphFormat",
    "paragraphStyle",
    "help",
    "draggable",
    "align",
    "fontSize",
    "link",
    "lists",
    "url",
    "video",
    "embedly",
    "colors",
    "entities",
    "inlineClass",
    "inlineStyle",
    // 'codeBeautif '
    // 'spellChecker',
    "imageTUI"
  ]
}

export const routes = _routes
export const editorConfig = _editorConfig
