import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Image } from 'semantic-ui-react'
import Cookies from 'universal-cookie'
const configs = require('../../../../config/configs.js')

const EditLabsCard = ({
  id,
  setEditLabBlock,
  isLoading,
  setIsLoading,
  start_time,
}) => {
  const [end_time, setEndtime] = useState('')
  const [startDate, setStartDate] = useState('')

  const cookies = new Cookies()
  useEffect(() => {
    if (!id) {
      alert('No lab id found please contact labs team')
      setEditLabBlock('false')
    }
    const currentDate = new Date(start_time)
    const isoTime = currentDate.toISOString()
    const date = isoTime.slice(0, 10)
    const time = isoTime.slice(11, 16)
    setStartDate(`${date}T${time}`)
  }, [id, setEditLabBlock, start_time])

  const submitHanlder = (e) => {
    e.preventDefault()
    try {
      const editLabsRequest = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }
        const data = {
          id: id,
          end_time: end_time,
        }

        setIsLoading(true)
        const res = await Axios.post(
          `${baseURL}/cfpanellabs/editLabs`,
          data,
          config
        )

        if (res) {
          if (res.status !== 200) {
            alert(res.data.message)
          }
        }
        setIsLoading(false)
        setEditLabBlock(false)
      }
      editLabsRequest()
    } catch (error) {
      console.error(error.message)
    }
  }

  return (
    <div className="labs-add-student-card-main">
      <div className="labs-add-student-card-content">
        <div className="labs-add-student-card-header">
          <span>Edit Deletion Time</span>

          <Image
            src="https://cdn.pegasus.imarticus.org/labs/cross.svg"
            onClick={() => setEditLabBlock(false)}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <hr />
        <form onSubmit={(e) => submitHanlder(e)}>
          <Row>
            <Col md={12}>
              <div className="labs-create-new-form">
                <div>Enter Deletion Time</div>
                <input
                  type="datetime-local"
                  min={startDate}
                  name="end_time"
                  onChange={(e) => {
                    setEndtime(e.target.value)
                  }}
                  className="labs-create-new-form-text_input"
                  required={true}
                />
              </div>
            </Col>

            <Col md={12}>
              <div className="labs-create-new-form">
                <input
                  type="text"
                  name="id"
                  value={id}
                  className="labs-create-new-form-text_input"
                  required={true}
                  hidden={true}
                  disabled
                />
              </div>
            </Col>
            <Col md={12}>
              <Col md={6}>
                <button
                  className="labs-table-btn-1"
                  type="submit"
                  disabled={isLoading}
                >
                  Edit Labs{' '}
                </button>
              </Col>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  )
}

export default EditLabsCard
