const initialstate = {
  groups: null,
  courses: null,
  Report: [],
  showPermitWar: false,
  success: false,
  delwar: false,
}

export default function (state = initialstate, action) {
  switch (action.type) {
    case 'FETCH_BATCHES':
      return {
        ...state,
        courses: action.payload,
      }
    case 'FETCH_GROUPS':
      console.log('Reached here')
      return {
        ...state,
        groups: action.payload,
      }
    case 'ADD_ROW':
      console.log(action.payload)
      var obj = {
        courseName: action.payload.courseName,
        coursePage: action.payload.coursePage,
        gcode: action.payload.gcode,
      }
      // console.log(state.Report)
      var ReportN = state.Report
      ReportN.push(obj)
      return {
        ...state,
        Report: ReportN,
      }
    case 'GET_REPORT':
      return {
        ...state,
        Report: action.payload,
      }
    case 'NOT_PERMITTED':
      return {
        ...state,
        showPermitWar: true,
      }
    case 'REMOVE_WARNING':
      return {
        ...state,
        showPermitWar: false,
      }
    case 'SUCCESS_MESSAGE':
      return {
        ...state,
        success: true,
      }
    case 'REMOVE_SUCCESS':
      return {
        ...state,
        success: false,
      }
    case 'DELETE_MESSAGE':
      return {
        ...state,
        delwar: true,
      }
    case 'REMOVE_DELETE':
      return {
        ...state,
        delwar: false,
      }
    case 'REMOVE_DATA':
      return {
        ...state,
        Report: [],
      }
    default:
      return state
  }
}
