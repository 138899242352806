import React, { useEffect, useState } from 'react'
import { Input } from 'reactstrap'
import Sidebar from '../Sidebar'
import TopBar from '../TopBar'
import { Col, Row } from 'react-bootstrap'
import '../../LabsManagement.css'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import { history } from '../../../..'
import { Loader } from 'semantic-ui-react'
const configs = require('../../../../config/configs.js')

const AddImage = () => {
  const [cloud, setCloud] = useState('')
  const [minRam, setMinRam] = useState('')
  const [types, setTypes] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [region, setRegion] = useState('')
  const [os, setOS] = useState('')
  const [formdata, setFormdata] = useState({
    name: '',
    cloud_provider: '',
    cloud_image_id: '',
    min_ram: '',
    description: '',
    type: 0,
    module: '',
    sg: '',
    region: '',
    os: '',
    snapshot_id: '',
    min_disk: '',
  })

  const handleFile = (e) => {
    let file = e.target.files[0]
    if (file === undefined) return
    const newData = {
      ...formdata,
      [e.target.name]: file,
    }
    setFormdata(newData)
  }

  const submitHanlder = (e) => {
    const cookies = new Cookies()
    e.preventDefault()

    const data = new FormData()

    data.append('admin_aid', cookies.get('aid'))
    data.append('name', formdata.name)
    data.append('cloud_provider', formdata.cloud_provider)
    data.append('cloud_image_id', formdata.cloud_image_id)
    data.append('min_ram', formdata.min_ram)
    data.append('description', formdata.description)
    data.append('sg', formdata.sg)
    data.append('type', formdata.type)
    data.append('region', formdata.region)
    data.append('os', formdata.os)
    data.append('snapshot_id', formdata.snapshot_id)
    data.append('min_disk', formdata.min_disk)
    data.append('file', formdata.module)

    try {
      setIsLoading(true)
      const createLabsRequest = async () => {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': cookies.get('at'),
            'Access-Control-Allow-Origin': '*',
          },
        }
        const res = await Axios.post(
          `${baseURL}/cfpanellabs/addImage`,
          data,
          config
        )

        if (res) {
          if (res.status === 200) {
            history.push('/labs-management/images')
          } else {
            alert(res.data.message)
          }
        }
      }
      createLabsRequest()
      setIsLoading(false)
    } catch (error) {
      console.error(error.message)
    }
  }

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const fetchConstants = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          admin_aid: cookies.get('aid'),
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getImageConstants`,
          data,
          config
        )

        if (res.data.data) {
          const res1 = res.data.data

          setCloud(res1.cloudProvider)
          setMinRam(res1.minRam)
          setTypes(res1.types)
          setOS(res1.operating_system)
          setRegion(res1.region)
        }
        setIsLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    fetchConstants()
  }, [])

  const upadteFormdata = (e) => {
    const newData = {
      ...formdata,
      [e.target.name]: e.target.value,
    }
    setFormdata(newData)
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={2} />
      <div className="labs-main" style={{ paddingBottom: '10vh' }}>
        <TopBar />
        <div className="labs-content">
          <div>
            <div className="labs-create-new-header">Add New Image</div>
            <hr />
            <form onSubmit={(e) => submitHanlder(e)}>
              <Row>
                <Col md={6}>
                  <div className="labs-create-new-form">
                    <div>Enter Image Name</div>
                    <input
                      type="text"
                      name="name"
                      onChange={upadteFormdata}
                      className="labs-create-new-form-text_input"
                      required={true}
                    />
                  </div>
                </Col>

                <Col md={6}>
                  <div className="labs-create-new-form">
                    <div>Enter Description</div>
                    <input
                      className="labs-create-new-form-text_input"
                      required={true}
                      type="text"
                      name="description"
                      onChange={upadteFormdata}
                    ></input>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Cloud Provider</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="cloud_provider"
                      onChange={upadteFormdata}
                    >
                      <option value="">Select Cloud Provider</option>
                      {cloud
                        ? Object.keys(cloud).map((key, i) => {
                            return (
                              <option value={cloud[key]} key={i}>
                                {key}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Enter Cloud Image ID</div>
                    <input
                      type="text"
                      name="cloud_image_id"
                      onChange={upadteFormdata}
                      className="labs-create-new-form-text_input"
                      required={true}
                    />
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Minimum RAM</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="min_ram"
                      onChange={upadteFormdata}
                      min={8}
                      max={100}
                    >
                      <option value="">Select Minimum RAM</option>
                      {minRam
                        ? Object.keys(minRam).map((key, i) => {
                            return (
                              <option value={key} key={i}>
                                {minRam[key]}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Minimum Disk</div>
                    <input
                      className="labs-create-new-form-text_input"
                      required={true}
                      type="number"
                      name="min_disk"
                      min={8}
                      onChange={upadteFormdata}
                    ></input>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Region</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="region"
                      onChange={upadteFormdata}
                      min={8}
                      max={100}
                    >
                      <option value="">Select Region</option>
                      {region
                        ? Object.keys(region).map((key, i) => {
                            return (
                              <option value={key} key={i}>
                                {region[key]}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Operating System</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="os"
                      onChange={upadteFormdata}
                      min={8}
                      max={100}
                    >
                      <option value="">Select Operating System</option>
                      {os
                        ? Object.keys(os).map((key, i) => {
                            return (
                              <option value={os[key]} key={i}>
                                {key}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Enter Security Group</div>
                    <input
                      className="labs-create-new-form-text_input"
                      required={true}
                      type="text"
                      name="sg"
                      onChange={upadteFormdata}
                    ></input>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Enter Snapshot ID</div>
                    <input
                      className="labs-create-new-form-text_input"
                      required={true}
                      type="text"
                      name="snapshot_id"
                      onChange={upadteFormdata}
                    ></input>
                  </div>
                </Col>

                <Col md={3}>
                  <div className="labs-create-new-form">
                    <div>Select Type</div>
                    <select
                      className="labs-create-new-form-text_input"
                      required={true}
                      name="type"
                      onChange={upadteFormdata}
                    >
                      <option value="">Select Cloud Provider</option>
                      {types
                        ? Object.keys(types).map((key, i) => {
                            return (
                              <option value={Number(types[key])} key={i}>
                                {key}
                              </option>
                            )
                          })
                        : null}
                    </select>
                  </div>
                </Col>
                {Number(formdata.type) === 2 && (
                  <Col md={3}>
                    <div className="labs-create-new-form">
                      <div>Module</div>
                      <Input
                        type="file"
                        name="module"
                        className="labs-create-new-form-text_input"
                        required={Number(formdata.type) === 2}
                        onChange={handleFile}
                        accept=".yml,.yaml"
                      />
                    </div>
                  </Col>
                )}

                <Col md={12}>
                  <Col md={2}>
                    <button className="labs-table-btn-1" type="submit">
                      Create Image
                    </button>
                  </Col>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddImage
