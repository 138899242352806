import React, { Fragment } from "react";
import styles from "./CourseHeader.scss";

import { connect } from "react-redux";
import {
  URLS,
  SIDEBARS,
  POPOVER_TYPE,
} from "../../../constants/constant";
import { useState, useEffect } from "react";

const urlParams = new URLSearchParams(window.location.search);
let PlacementHeader = (props) => {
  const [show_sidebar, setShow_sidebar] = useState(false);
  const [is_search, setIs_search] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    msg: "",
    type: POPOVER_TYPE.SUCCESS,
  });

  let handleClick = (event) => {
    // event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };

  return (
    <Fragment>
      <div className="home-dashboard-header courseHeader">
        <div
          className={
            props.sidebar == SIDEBARS.HOME_SIDEBAR
              ? "nav-bar fixed-header"
              : "nav-bar fixed-gdtl-header"
          }
        >
          <div className="header-content">
            <div className="toggle-icon-container-mb">
            </div>
            <div className="profile-search-container-mb">
            </div>
            {props.breadCrumbs && props.breadCrumbs[0] && (
              <div className="header-heading2" style={{ marginLeft: "4%" }}>
                <a href="/">
                  <span
                    className="heading-text2 rvm-v1-header-brdsc-ctnr"
                    style={{
                      overflow: "unset",
                      paddingLeft: "12px",
                      paddingRight: "12px",
                      fontSize: "16px",
                    }}
                  >
                    {props.breadCrumbs[0].name}
                  </span>
                </a>
                <span>
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: "small" }}
                  ></i>
                </span>
                {props.active && props.active === 1 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                  }}
                >
                  Job Postings
                </span>
                )}
                {props.active && props.active === 2 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Application Status
                </span>
                )}
                {props.active && props.active === 3 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Pending Job Reviews
                </span>
                )}
                {props.active && props.active === 4 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Exception Approval Requests
                </span>
                )}
                {props.active && props.active === 5 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Resume Reviews
                </span>
                )}
                {props.active && props.active === 6 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Manage Learners
                </span>
                )}
                {props.active && props.active === 7 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Placement Settings
                </span>
                )}
                {props.active && props.active === 8 && (  
                <span
                  className="heading-text3"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                  }}
                >
                  Placement Companies
                </span>
                )}
              </div>
            )}

            {/* <Link to={props.back_url} onClick={props.resetBackBtn}>
              <div
                className={props.is_back ? "header-heading back-btn" : "hide"}
              >
                <i className="fa fa-angle-down bold-angle-left"></i>
                <span>{!props.is_data_loading ? "Back" : ""}</span>
              </div>
            </Link> */}
            <div className="header-center"></div>
          </div>
        </div>
        <div className={props.show_menu ? "header-cover" : "hide"}></div>
      </div>
    </Fragment>
  );
};

const setPageHeading = (pg_heading) => (dispatch) => {
  dispatch({
    type: "SET_PAGE_HEADING",
    pg_heading: pg_heading,
  });
};

const mapStateToProps = (state) => ({
  // pg_heading: state.utils.pg_heading,
  // sidebar: state.utils.sidebar,
  // back_url: state.utils.back_url,
  // is_back: state.utils.is_back,
  // is_data_loading: state.utils.is_data_loading,
  // brand: state.course.individualBrand,
});

const mapDispatchToProps = {
  // setSearch,
  // resetBackBtn,
  // showSidebarMB,
  // setLoadingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlacementHeader);
