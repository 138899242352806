import React, { useState } from 'react'
import globalContext from './context'

let CourseProvider = (props) => {
  let [coursePage, setCoursePage] = useState({ name: '' })
  let [batch, setBatch] = useState({ name: '' })
  let [childBatches, setChildBatches] = useState([])

  return (
    <globalContext.Provider
      value={{
        course: coursePage,
        selectCourse: (course) => {
          console.log({ receivedCourse: course })
          setCoursePage(course)
        },
        batch: batch,
        selectBatch: (batch) => {
          console.log({ receivedBatch: batch })
          setBatch(batch)
        },
        childBatches: childBatches,
        setChildBatches: (batches) => {
          console.log({ receivedBatches: batches })
          setChildBatches(batches)
        },
      }}
    >
      {props.children}
    </globalContext.Provider>
  )
}
export default CourseProvider
