import {
  COURSE_CHANGE,
  COURSE_PAGE_CHANGE,
  ROWS_PER_PAGE_CHANGE,
} from '../actions/actiontypes'
import { STATUS_CHANGE } from '../actions/actiontypes'
import { ASSIGNMENT_CHANGE } from '../actions/actiontypes'
import { SAVE_STUDENT_DETAIL } from '../actions/actiontypes'
import { SAVE_MSG } from '../actions/actiontypes'
import { SHOW_ERROR_MSG } from '../actions/actiontypes'
import { LOCATION_CHANGE } from '../actions/actiontypes'
import { PAGE_CHANGE } from '../actions/actiontypes'
import { ROWS_PER_CHANGE } from '../actions/actiontypes'

var defaultProps = {
  course: 'ALL',
  status: 'ALL',
  coursePage: '',
  locationId: 'ALL',
  page: 0,
  rowsPerPage: 50,
  assignment: 'ALL',
}

export default (state = { defaultProps }, action) => {
  switch (action.type) {
    case PAGE_CHANGE:
      return {
        ...state,
        page: action.value,
      }
      break
    case ROWS_PER_PAGE_CHANGE:
      return {
        ...state,
        page: 0,
        rowsPerPage: action.value,
      }
      break
    case COURSE_CHANGE:
      return {
        ...state,
        course: action.value,
        assignment: '',
      }
      break
    case LOCATION_CHANGE:
      return {
        ...state,
        location: action.value,
        course: '',
        assignment: '',
      }
      break
    case COURSE_PAGE_CHANGE:
      console.log(
        '/////////////////////////////////////////////////////////',
        { state },
        '//////////////////////////////'
      )
      return {
        ...state,
        coursePage: action.value,
        location: '',
        course: '',
        assignment: '',
      }
      break
    case ASSIGNMENT_CHANGE:
      return { ...state, assignment: action.value }
      break
    case STATUS_CHANGE:
      return { ...state, status: action.value }
      break
    case SAVE_STUDENT_DETAIL:
      return { ...state, data: action.data }
      break
    case SAVE_MSG:
      console.log(
        '\n\n /////////////////////////////////////////////////////////stateInSaveMessage: ',
        state,
        '////////////////////////////\n\n'
      )
      return {
        ...state,
        // coursePage: "",
        // location: "",
        // course: "",
        // assignment: "",
        open: action.open,
        msg: action.msg,
      }
      break
    case SHOW_ERROR_MSG:
      return { ...state, open: action.open, msg: action.msg }
      break

    default:
      return state
  }
}
