import React from "react"
import {Modal, ModalBody, Button} from "reactstrap"
import "../ScheduleManagement/ScheduleManagement.scss"

const MarkAttendanceModal = ({confirmationModalToggle, setConfirmationModalToggle, markAttendance}) => <Modal
  isOpen={confirmationModalToggle}
  toggle={e => setConfirmationModalToggle(false)}
  className="width30"
  centered={true}
  size={"sm"}
>
  <ModalBody style={{flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "40px 30px", gap: "16px", textAlign: "center"}}>
    <h4>Mark Present</h4>
      <p style={{fontSize: "15px"}}>Are you sure you want to mark the learners attendance as Present?</p>
    <Button style={{width: "150px", background: "#014644", color: "white"}} color="success" onClick={e => markAttendance(e, true)}>
      Yes
    </Button>{' '}   
    <Button style={{width: "100px"}} color="danger" onClick={e => setConfirmationModalToggle(false)}>
      No
    </Button>{' '}
  </ModalBody>
</Modal>

export default MarkAttendanceModal