import React from "react"
import { convertToHours, epochToDate, epochToTime } from "../ScheduleManagementHelper"
import { Button } from "reactstrap"
import NoLogs from "../../ChangeStreamManagement/NoLogs.svg"

const Upcoming = ({metricsData, page, isLoading, setIsLoading, classMap, lectures, chapMap}) => {
    console.log("UPCOMING :: ", {metricsData, page, isLoading, setIsLoading, classMap, lectures})
    
    return <>
        <br/>
        {lectures && lectures.length > 0 ?
            <table className="table table-bordered table-responsive facultyDashboardTable">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Chapter Name</th>
                        <th>Topic Name</th>
                        <th>Number of Hours</th>
                        <th>Schedule Date</th>
                        <th>Time</th>
                        <th>Classroom</th>
                        <th>Zoom Link</th>
                    </tr>
                </thead>
                <tbody>
                    {lectures.map((l,idx) => 
                        <tr>
                            <td>{idx+1}</td>
                            <td className="greyColoredBg">{chapMap[l.chpid]?.nm || "-"}</td>
                            <td className="greyColoredBg">{l.nm || "-"}</td>
                            <td className="greyColoredBg">{convertToHours(l.tot_tim)}</td>
                            <td>{epochToDate(l.stm)}</td>
                            <td>{epochToTime(l.stm)}</td>
                            <td>{l.cl_id && classMap[l.cl_id]?.name || "-"}</td>
                            <td style={{textAlign:"center"}}>{l.url ? <a href={l.url} target={"_blank"} > Join link</a> : "-"}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        : 
            <div style={{display: "flex", flexDirection:"column", justifyContent: "center", alignItems:"center"}}>
                <img src={NoLogs} width="400px"/>
                <br/>
                <h4>Its empty here!</h4>
                <p>Select the Course and Batch, and we will fetch you your lectures!</p>
            </div>
        }
    </>
}
export default Upcoming