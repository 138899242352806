import React from "react"
import {Modal, ModalBody, Button} from "reactstrap"
import Tick from '../ScheduleManagement/images/tick.svg'

const ResponseInfoModal = ({reponseInfoModal, setReponseInfoModal, markAttendance, notSameAlert = false, msg = "", setIfRefresh}) => <Modal
  isOpen={reponseInfoModal}
  toggle={e => setReponseInfoModal(false)}
  className="width30"
  centered={true}
  size={"sm"}
>
  <ModalBody style={{flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "40px 30px", gap: "16px", textAlign: "center"}}>
    <h4 style={notSameAlert ? {color: "#DC3545"}: {}}>{notSameAlert ? "Alert!" : "Attendance Marked Successfully!"}</h4>
      {!notSameAlert && <><img width={100} src={Tick}/> <br/><br/></>}
      {notSameAlert && <p style={{fontSize: "15px"}}>{msg}</p>}
    <Button style={{width: "100px", background: "#014644", color: "white"}} color="success" onClick={e => {setReponseInfoModal(false); !notSameAlert && setIfRefresh(true); return;}}>
      Okay
    </Button>{' '}
  </ModalBody>
</Modal>

export default ResponseInfoModal