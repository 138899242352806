import React, { useEffect, useState } from 'react'
import '../../LabsManagement.css'
import TopBar from '../TopBar'
import Sidebar from '../Sidebar'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import Axios from 'axios'
import Cookies from 'universal-cookie'
import {
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
} from '@mui/material'
import { Loader } from 'semantic-ui-react'
import {
  LABS_OS,
  LABS_SERVER,
  LABS_TYPES,
} from '../../../../constants/constant'
const configs = require('../../../../config/configs.js')

const LabsImages = () => {
  const [requests, setRequests] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [totalCount, setTotalCount] = useState(0) // Total number of rows
  const [page, setPage] = useState(0) // Current page number
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const cookies = new Cookies()
    setIsLoading(true)
    const getRequests = async () => {
      try {
        const baseURL = configs.routes.BASE_URL_PRODUCTION
        const config = {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': cookies.get('at'),
          },
        }

        const data = {
          page,
          rowsPerPage,
        }

        const res = await Axios.post(
          `${baseURL}/cfpanellabs/getAllImages`,
          data,
          config
        )

        if (res.data.data) {
          setRequests(res.data.data.labsImage)
          setTotalCount(parseInt(res.data.data.totalImages))
        }
        setIsLoading(false)
      } catch (error) {
        console.error(error.message)
      }
    }
    getRequests()
  }, [refresh, page, rowsPerPage])

  const handleChangePage = (newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0) // Reset page number when rows per page changes
  }

  if (isLoading) {
    return (
      <div
        style={{ marginLeft: '800px', marginTop: '400px', position: 'fixed' }}
      >
        <Loader active inline />
      </div>
    )
  }

  return (
    <>
      <Sidebar active={2} />
      <div className="labs-main">
        <TopBar />
        <div className="labs-content">
          <Row>
            <Col md={8} className="labs-table-header">
              Labs Image Management
            </Col>

            <Col md={2}>
              <button
                onClick={() => (refresh ? setRefresh(false) : setRefresh(true))}
                className="labs-table-btn-2"
              >
                Refresh
              </button>
            </Col>
            <Col md={2}>
              <Link to="/labs-management/addImage">
                <button className="labs-table-btn-1">+ Create</button>
              </Link>
            </Col>
          </Row>

          <Row style={{ marginTop: '1rem' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead className="labs-table-req-head">
                  <TableCell>Image Name</TableCell>
                  <TableCell>Server</TableCell>
                  <TableCell>Region</TableCell>
                  <TableCell>Image ID</TableCell>
                  <TableCell>Operating System</TableCell>
                  <TableCell>Security Group</TableCell>
                  <TableCell>Snapshot ID</TableCell>
                  <TableCell>Minimum Ram</TableCell>
                  <TableCell>Minimum Disk</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Script</TableCell>
                </TableHead>
                <tbody>
                  {requests
                    ? requests.map((elem, i) => {
                        return (
                          <tr key={i}>
                            <td>{elem.name}</td>
                            <td>{LABS_SERVER[elem.cloud_provider]}</td>
                            <td>{elem.region}</td>
                            <td>{elem.cloud_image_id}</td>
                            <td>{LABS_OS[elem.os]}</td>
                            <td>{elem.sg}</td>
                            <td>{elem.snapshot_id}</td>
                            <td>{elem.min_ram}</td>
                            <td>{elem.min_disk && elem.min_disk + 'gb'}</td>
                            <td>{elem.description}</td>
                            <td>{LABS_TYPES[elem.type]}</td>
                            <td>
                              {elem.ansible_script_link
                                ? elem.ansible_script_link
                                : 'NA'}
                            </td>
                          </tr>
                        )
                      })
                    : null}
                </tbody>
              </Table>
            </TableContainer>

            <div style={{ marginRight: '256px' }}>
              <TablePagination
                rowsPerPageOptions={[10, 50, 75, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          </Row>
        </div>
      </div>
    </>
  )
}

export default LabsImages
