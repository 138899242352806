import React from 'react'
import { history } from '../../..'
import { connect } from 'react-redux' 

const Sidebar = ({ active }) => {

  let props = {}
  const checkVisibility = (resource,resourceVal = 1) => {

    if (!props.permissions) {
      return true
    } else {
      if (!props.permissions[resource]) {
        return false
      } else if (props.permissions[resource].indexOf(resourceVal) != -1) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <div className="labs-sidebar">
      <div
        className={`labs-sidebar-elem`}
        onClick={() => {
          history.push('/career-services/application-status')
        }}
      >
       &nbsp;&nbsp;&nbsp; Back
      </div>
    </div>
  )
}

// export default Sidebar
const mapStateToProps = (state) => {
  return {
    permissions: state.panelPermissionReducer.panelPermissions,
    resources: state.panelPermissionReducer.panelResources,
  }
}

export default connect(mapStateToProps)(Sidebar)