const initialState = {
  user: null,
  status: null,
  sortOn: null,
  c_req: null,
  cfLoading: false,
  count: 0,
  pn: 0,
  cp_a: null,
  cp_i: null,
  detailsNotSaved: false,
}
export default function (state = initialState, action) {
  switch (action.type) {
    case 'SET_CERTI_REQUEST':
      return {
        ...state,
        c_req: action.payload.c_req,
        count: action.payload.count,
      }
    case 'SET_PAGE_NUMBER':
      return {
        ...state,
        pn: action.payload.pn,
      }
    case 'APPEND_CERTI_REQUEST':
      return {
        ...state,
        c_req: state.c_req.concat(action.payload.c_req),
      }
    case 'GET_COURIER_PARTNERS':
      return {
        ...state,
        cp: action.payload.cp,
      }
    case 'GET_COURSES_IN_CREQS':
      return {
        ...state,
        courses: action.payload.courses,
      }
    case 'GET_USER_DETAILS':
      return {
        ...state,
        ud: action.payload.ud,
        crtf_d_url: action.payload.crtf_d_url,
      }
    case 'UPDATE_TRACKING_INFO':
      return {
        ...state,
        c_req: state.c_req.map((item) => {
          if (item._id == action.payload.creq_id) {
            item.api_status = action.payload.courier_status
            item.ds_date = action.payload.d_date
            item.status = action.payload.status
          }
          return item
        }),
      }
    case 'DETAILS_NOT_SAVED':
      return {
        ...state,
        detailsNotSaved: action.payload,
      }
    case 'LOADING_TRUE':
      return {
        ...state,
        cfLoading: true,
      }
    case 'LOADING_FALSE':
      return {
        ...state,
        cfLoading: false,
      }
    case 'GET_COURIER_PARTNERS_ACTIVE':
      return {
        ...state,
        cp_a: action.payload.cp_a,
      }
    case 'GET_COURIER_PARTNERS_INACTIVE':
      return {
        ...state,
        cp_i: action.payload.cp_i,
      }
    case 'REMOVE_COURIER_PARTNER': {
      var cpname
      return {
        ...state,
        cp_a: state.cp_a.filter(({ _id, name }, index) => {
          if (_id !== action.payload.cp_id) {
            return true
          } else {
            cpname = name
            return false
          }
        }),
        cp_i: state.cp_i.concat([{ _id: action.payload.cp_id, name: cpname }]),
      }
    }

    case 'ADD_COURIER_PARTNER': {
      var cpname
      return {
        ...state,
        cp_i: state.cp_i.filter(({ _id, name }, index) => {
          if (_id !== action.payload.cp_id) {
            return true
          } else {
            cpname = name
            return false
          }
        }),
        cp_a: state.cp_a.concat([{ _id: action.payload.cp_id, name: cpname }]),
      }
    }
    default:
      return state
  }
}
