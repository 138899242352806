var defaultProps = {
  isLoading: false,
  loaded: false,
  loadError: false,
}
export default (state = defaultProps, action) => {
  switch (action.type) {
    case 'START_FETCH_DATA':
      return { isLoading: true, loaded: false, loadError: false }

    case 'ERROR_FETCH_DATA':
      return { isLoading: false, loaded: false, loadError: true }

    case 'SUCCESS_FETCH_DATA':
      return { ...state, loaded: true, isLoading: false }
    default:
      return state
  }
}
